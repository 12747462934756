import {
    useRef,
    useState
} from "react"

// Contexts
import {
    useGlobals
} from "contexts"

// Elements
import {
    Heartbeat
} from "elements"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Styles
import "./Dropdown.css"
      
export const Dropdown = ({mode}) => {
    const globals = useGlobals()
    const [opened, setOpened] = useState(0)
    
    const selection0 = useRef(ROTANA_DEMO_MODE ? undefined : "_")
    // const selection = useRef(ROTANA_DEMO_MODE ? undefined : "_")
    
    const selection1 = useRef(globals?.context?.dropdown_selection) // || (ROTANA_DEMO_MODE ? undefined : "_"))
    const selection2 = useRef()
    const selection3 = useRef()
    const selection4 = useRef()
    const selection5 = useRef()
    const selection6 = useRef()
    const selection7 = useRef()



    

    // const groupName = [
    //   // Mode 0 - Timeframes
    //   {
    //     "_": "All timeframes",
    //     "l0": "Today",
    //     "l7": "Last week",
    //     "l30": "Last month",
    //     "l365": "Last year"
    //   },
    //   // Mode 1 - Groups (group distribution) 
    //   {
    //     "_": "All groups",
    //     "high": "High engagement",
    //     "medium": "Mid engagement",
    //     "low": "Low engagement"
    //   }
    // ][(isNaN(mode) || mode > 1) ? 1 : mode][ROTANA_DEMO_MODE ? selection1.current : "_"]



    const groupName = [
      // Mode 1 - Groups (group distribution) 
      {
        "_": "All groups",
        "high": "High engagement",
        "medium": "Mid engagement",
        "low": "Low engagement"
      }
    ][0][ROTANA_DEMO_MODE ? selection1.current : "_"]

    // const groupName0 = [
    //   // Mode 0 - Timeframes
    //   {
    //     "_": "All timeframes",
    //     "l0": "Today",
    //     "l7": "Last week",
    //     "l30": "Last month",
    //     "l365": "Last year"
    //   }
    // ][0][ROTANA_DEMO_MODE ? selection0.current : "_"]





    return (

    <div className={`dropdown ${ROTANA_DEMO_MODE && (mode > 1) ? "dropdown--disabled" : ""} ${ROTANA_DEMO_MODE ? "dropdown--temp-rotana" : ""} dropdown--${(!ROTANA_DEMO_MODE ? selection1.current : (mode === 0 ? "l30"/*<- HARDCODED_UNTIL_MORE_TIMEFRAMES_AVAILABLE) selection0.current*/ : (mode === 1 ? selection1.current : "undefined")))} text-xs-b`} onClick={() => setOpened(!opened)}>

      {/* Group distribution */}
      {
      mode === 0
      ? 
      <>
        <div className={`dropdown__flag dropdown__flag-${selection0.current}`}></div>

        <span>{ROTANA_DEMO_MODE ? "Last month" : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {/* <div className={`dropdown__flag dropdown__flag-${selection0.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection0.current ? groupName0 : "Timeframe") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div> */}
        
        {opened ? <div className={`dropdown__table dropdown__table-alt`}>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l0")}>
            Today
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l7")}>
            This week
          </div>
          <div className="text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l30")}>
            This month
          </div>
          <div className="dropdown__table__row--disable text-xs" onClick={() => globals.set("dropdown_selection0_unlinked_dummy", selection0.current = "l365")}>
            This year
          </div>
        </div> 
        : ""}
      </>
      : 
      mode === 1
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection1.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection1.current ? groupName : "Group") : "Group distribution"}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection1.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div>
        </div> 
        : ""}
      </>
      : 
      mode === 2
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection2.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection2.current ? groupName : "Audience") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection2.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection2.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection2.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection2.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 3
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection3.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection3.current ? groupName : "Location") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection3.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection3.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection3.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection3.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 4
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection4.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection4.current ? groupName : "Title") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection4.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection4.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection4.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection4.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 5
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection5.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection5.current ? groupName : "IAB") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection5.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection5.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 6
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection6.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection6.current ? groupName : "Device") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection6.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection6.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection6.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection6.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      mode === 7
      ?
      <>
        <div className={`dropdown__flag dropdown__flag-${selection7.current}`}></div>
        <span>{ROTANA_DEMO_MODE ? (selection7.current ? groupName : "Platform") : "..."}</span><div className={"dropdown-arrow dropdown-arrow-" + (opened ? "up" : "dn")}></div>
        {opened ? <div className={`dropdown__table`}>
          {/* <div onClick={() => globals.set("dropdown_selection", selection7.current = "_")}>
            <div></div><div className="text-xs">All groups</div><Heartbeat /><div className="text-xxs-b">0-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "high")}>
            <div></div><div className="text-xs">High engagement</div><Heartbeat /><div className="text-xxs-b">71-100</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "medium")}>
            <div></div><div className="text-xs">Medium engagement</div><Heartbeat /><div className="text-xxs-b">31-70</div>
          </div>
          <div onClick={() => globals.set("dropdown_selection", selection7.current = "low")}>
            <div></div><div className="text-xs">Low engagement</div><Heartbeat /><div className="text-xxs-b">0-30</div>
          </div> */}
        </div> 
        : ""}
      </>
      : 
      ""
      }
    </div>
    )
}