// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
    Grid,
    Layout
} from "containers"

// Components
import {
  TableSort,
  Chart
} from "components"

// Elements
import {
  CheckboxGroup,
  Insert,
  Tick
} from "elements"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Devices-wide styling
import "./Devices.css"

// Remember first access (& avoid re-rendering)
// let firstAccess = true

  // TODO: Merge all data (ugly last-minute workaround)
  // const arr_all_obj = {
  //   "_": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   },
  //   "high": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   },
  //   "medium": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   },
  //   "low": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   }
  // }[group] 
  // arr_sel.push(arr_all_obj[text.toLowerCase().replaceAll(" ", "_")])



// TODO: Merge all data (ugly last-minute workaround)
// const arr_all = [
//   [52254, 37, [71.71, 78.87, 82.05]],// Reach (bar), Heartbeat (orb), H/M/L (ticks)
//   [3075,  39, [22.82, 16.65, 3.8  ]],
//   [0,      0, [0,     0,     0    ]],
//   [11802, 40, [5.47,  4.48,  14.15]]
// ]
// const arr_all_obj = {
//   "android": [52254, 37, [71.71, 78.87, 82.05]],
//   "other_device": [3075,  39, [22.82, 16.65, 3.8  ]],
//   // "windows_pc": [0,      0, [0,     0,     0    ]],
//   "iphone": [11802, 40, [5.47,  4.48,  14.15]]
// }

// Devices assembly
export const Devices = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  // const group = globals?.context?.selected?.[0] || "_"
  // const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"

  // // Dummy data loader (snow owl demo only)
  // const arr_all = {
  //   "_": [
  //     [0, 1162, 1144],
  //     [0, 1117, 1170],
  //     [0, 1166, 1141],
  //     [0, 1127, 1156],
  //   ],
  //   "high": [
  //     [52254, 37, {h: 71.71, m: 78.87, l: 82.05}[group]],// ???, Reach (bar), Heartbeat (orb), H/M/L (tick)
  //     [3075, 39, {h: 22.82, m: 16.65, l: 3.8}[group]],
  //     [0, 0, {h: 0, m: 0, l: 0}[group]],
  //     [11802, 40, {h: 5.47, m: 4.48, l: 14.15}[group]]
  //   ],
  //   "medium": [
  //     [0, 42, 84],
  //     [0, 37, 63],
  //     [0, 43, 21],
  //     [0, 67, 34]
  //   ],
  //   "low": [
  //     [0, 53, 71],
  //     [0, 61, 15],
  //     [0, 74, 35],
  //     [0, 44, 22]
  //   ]
  // }[group] 

  // Dummy data loader (snow owl demo only)
  // const arr_all = [
  //   [52254, 37, {h: 71.71, m: 78.87, l: 82.05}[group]],// Reach (bar), Heartbeat (orb), H/M/L (tick)
  //   [3075,  39, {h: 22.82, m: 16.65, l: 3.8}[group]],
  //   [0,      0, {h: 0,     m: 0,     l: 0}[group]],
  //   [11802, 40, {h: 5.47,  m: 4.48,  l: 14.15}[group]]
  // ]

  // const arr_all = [
  //   [52254, 37, [71.71, 78.87, 82.05]],// Reach (bar), Heartbeat (orb), H/M/L (ticks)
  //   [3075,  39, [22.82, 16.65, 3.8  ]],
  //   [0,      0, [0,     0,     0    ]],
  //   [11802, 40, [5.47,  4.48,  14.15]]
  // ]

  // TODO: Prevent making this mapping redundant (we should either use "Foo Bar" formats at all times for labeling or have some kind of external mapping file for all components)
  const names = {
    // "windows_pc": "Windows PC",
    "android": "Android",
    "other_device": "Other device",
    "iphone": "iPhone",
    "windows_pc": "Windows PC",
    "unknown_device": "Unknown Device",
    "macos": "MacOS"
  }

  // const rows = {

  //   "_": [
  //     // --- Head
  //     ["Device", 12, 12, 12, 12, 12, 12, 12, 12],
      
  //     // --- Body
  //     // ["Windows PC", 0, 0, 0, 0, 0, 0, 0, 0],
  //     ["Android", 37, 52254, 77.84, 71.71, 78.87, 82.05, 13.904281, 36.50],
  //     ["Other device", 39, 3075, 4.58, 22.82, 16.65, 3.80, 9.682541, 28.70],
  //     ["iPhone", 40, 11802, 17.58, 5.47, 4.48, 14.15, 9.508275, 35.60]
  //   ],
  //   "high": [
  //     // --- Head
  //     ["Device", 12, 12, 12, 12, 12, 12, 12, 12],
      
  //     // --- Body
  //     // ["Windows PC", 0, 0, 0, 0, 0, 0, 0, 0],
  //     ["Android", 37, 52254, 77.84, 71.71, 78.87, 82.05, 13.904281, 36.50],
  //     ["Other device", 39, 3075, 4.58, 22.82, 16.65, 3.80, 9.682541, 28.70],
  //     ["iPhone", 40, 11802, 17.58, 5.47, 4.48, 14.15, 9.508275, 35.60]
  //   ],
  //   "medium": [
  //     // --- Head
  //     ["Device", 12, 12, 12, 12, 12, 12, 12, 12],
      
  //     // --- Body
  //     // ["Windows PC", 0, 0, 0, 0, 0, 0, 0, 0],
  //     ["Android", 37, 52254, 77.84, 71.71, 78.87, 82.05, 13.904281, 36.50],
  //     ["Other device", 39, 3075, 4.58, 22.82, 16.65, 3.80, 9.682541, 28.70],
  //     ["iPhone", 40, 11802, 17.58, 5.47, 4.48, 14.15, 9.508275, 35.60]
  //   ],
  //   "low": [
  //     // --- Head
  //     ["Device", 12, 12, 12, 12, 12, 12, 12, 12],
      
  //     // --- Body
  //     // ["Windows PC", 0, 0, 0, 0, 0, 0, 0, 0],
  //     ["Android", 37, 52254, 77.84, 71.71, 78.87, 82.05, 13.904281, 36.50],
  //     ["Other device", 39, 3075, 4.58, 22.82, 16.65, 3.80, 9.682541, 28.70],
  //     ["iPhone", 40, 11802, 17.58, 5.47, 4.48, 14.15, 9.508275, 35.60]
  //   ]
  // }[group]

  
  // const picks = globals.get("checkboxed") || ["windows_pc", "android", "other_device", "iphone"]/* First access */
  const picks = globals.get("checkboxed") || (/* First access */
    ROTANA_DEMO_MODE 
    ?
    ["android", "macos", "unknown_device", "windows_pc", "iphone"]
    : 
    ["android", "other_device", "iphone"]
  )


  let txt_sel = []
  let arr_sel = []
  let arr_dat = []
  let flags = []// [20000, 40000, 60000, 80000, 100000]
  if (ready) {
    /*JSON*/const arr_all_obj = ROTANA_DEMO_MODE ? globals?.context?.data_json_test?.campaigns?.devices_chart?.[group] : globals?.context?.data_json_test?.campaigns?.devices_chart
    /*JSON*/flags = globals?.context?.data_json_test?.campaigns?.devices_chart_flags 
    for (let i = 0; i < picks?.length; i++) {
      let text = names[picks[i].replaceAll("checkboxgroup-", "")]
      txt_sel.push(text)
      arr_sel.push(arr_all_obj[text?.toLowerCase().replaceAll(" ", "_")])
    }
    // arr_dat = rows
    /*JSON*/ arr_dat = [...globals?.context?.data_json_test?.campaigns?.devices_table?.[group]]
  }
  // --- Shared ---
  
  return (
    <Layout header={{
      "title": "Devices",
      "search": ""
    }}>

      {/* TODO: Include with real data */}
      <div className="_tempDummyHeader">Device</div>
  
      <Grid
        content={[
          ready/* Here too to animate bars on 1st load */ ? <Chart 
          text={{
            // title: "Revenue Impact",
            // subtitle: "Statistics",
            //x_label: "",
            x_flags: txt_sel,
            y_label: ROTANA_DEMO_MODE ? "Visits" : "Total reach", 
            y_flags: flags.map((x) => x.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ","))// TODO: Shared at the top of chart.jsx/mapchart.jsx & Lib method instead (shared w/ <Insert/> & others)
          }}
          data={arr_sel}
          mode={7}
        /> : "",
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 400
        }}
      />
      <Grid
        content={[
          <TableSort 
            data={arr_dat}
            stick={true}
            undim={{"high": 4, "medium": 5, "low": 6}[group]}
            mode={ROTANA_DEMO_MODE ? 2 : 0}
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: arr_dat?.length * 56 //270
        }}
      />

    </Layout>
)}