// Audiences-wide styling
import "./Audiences.css"

// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import {
    Grid,
    Layout
} from "containers"

// Components
import {
  Chart,
  TableSort
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Audiences assembly
export const Audiences = ({ready}) => {

    // --- Shared ---
    const globals = useGlobals() 

    // Unpack engagement grouping settings
    // const group = globals?.context?.selected?.[0] || "_"
    //const group = globals?.context?.selected?.[0] || "_"
    // const group = globals.get("dropdown_selection") || "_"
    const group = globals?.context?.dropdown_selection || "_"

    // const arr_all = {
    //   8:  [21000, 15, "m25c"],
    //   10: [6000,  25, "m30c"],
    //   16: [18000, 25, "m35c"],
    //   30: [10000, 45, "m40c"],
    //   48: [6000,  25, "m45c"],
    //   52: [17000, 15, "m50c"],
    //   58: [6000,  25, "m55c"],
    //   66: [16000, 15, "f25c"],
    //   69: [4000,  35, "f30c"],
    //   73: [18000, 35, "f35c"],
    //   77: [11000, 15, "f40c"],
    //   85: [14000, 15, "f45c"],
    //   91: [26000, 35, "f50c"],
    //   96: [7000,  15, "f55c"]
    // }

    let picks = globals.get("checkboxed") || (ROTANA_DEMO_MODE 
      ? ["f35c", "f80c", "f55c", "m45c", "m55c", "m60c", "m20c", "m75c", "f20c", "m25c", "f40c", "m80c", "f30c", "f15c", "m50c", "f50c", "f45c", "m70c", "m15c", "f70c", "m65c", "m40c", "m30c", "f60c", "f25c", "f65c", "f75c", "m35c"]
      : ["m25c", "m30c", "m35c", "m40c", "m45c", "m50c", "m55c", "f25c", "f30c", "f35c", "f40c", "f45c", "f50c", "f55c"]
    )

    // All audiences 
    // const names = {
    //   "m25c": [21000, 15, 8],
    //   "m30c": [6000,  25, 10],
    //   "m35c": [18000, 25, 16],
    //   "m40c": [10000, 45, 30],
    //   "m45c": [6000,  25, 48],
    //   "m50c": [17000, 15, 52],
    //   "m55c": [6000,  25, 58],
    //   "f25c": [16000, 15, 66],
    //   "f30c": [4000,  35, 69],
    //   "f35c": [18000, 35, 73],
    //   "f40c": [11000, 15, 77],
    //   "f45c": [14000, 15, 85],
    //   "f50c": [26000, 35, 91],
    //   "f55c": [7000,  15, 96]
    // }
    // const rows = {
    //   "_": [
    //     // --- Head
    //     ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
        
    //     // --- Body
    //     ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
    //   ],
    //   "high": [
    //     // --- Head
    //     ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
        
    //     // --- Body
    //     ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
    //   ],
    //   "medium": [
    //     // --- Head
    //     ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
        
    //     // --- Body
    //     ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
    //   ],
    //   "low": [
    //     // --- Head
    //     ["Audiences", 12, 12, 12, 12, 12, 12, 12, -2],
        
    //     // --- Body
    //     ["m25c", 21, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m30c", 22, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m35c", 23, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m40c", 24, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m45c", 25, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["m50c", 26, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["m55c", 27, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f25c", 28, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f30c", 29, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f35c", 30, 2972990, 100, 11, 43, 46, 41, 63],    
    //     ["f40c", 31, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f45c", 32, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f50c", 33, 2972990, 100, 11, 43, 46, 41, 63],
    //     ["f55c", 34, 2972990, 100, 11, 43, 46, 41, 63]
    //   ]
    // }[group]

    // let flags = [5000, 10000, 15000, 20000, 25000, 30000]

    let arr_all = {}
    let arr_dat = []
    let flags = []
    if (ready) {
      /*JSON*/const names = ROTANA_DEMO_MODE ? globals?.context?.data_json_test?.campaigns?.audiences_chart?.[group] : globals?.context?.data_json_test?.campaigns?.audiences_chart

      /*JSON*/flags = globals?.context?.data_json_test?.campaigns?.audiences_chart_flags
      for (let i = 0, j = 0; i < picks?.length; i++) {
        let text = picks[i].replaceAll("checkboxgroup-", "")

        // TODO: Avoid remapping of any kind [after harmonizing all the data for all sections]
        if (names?.[text]) {
          arr_all[names?.[text]?.[2]] = [names?.[text]?.[0], names?.[text]?.[1], text]
        } 
        // TODO (Original values Rotana [forced to be consecutive at _rotana.json]): 
        // "_": {
        //   "f35c": [194, 122, 42.0],
        //   "f80c": [193, 66, 44.0 ],
        //   "f55c": [456, 79, 47.0],
        //   "m45c": [174, 62, 47.0],
        //   "m55c": [68, 47, 42.0],
        //   "m60c": [153, 86, 41.0],
        //   "m20c": [126, 74, 63.0],
        //   "m75c": [106, 39, 47.0],
        //   "f20c": [173, 129, 66.0],
        //   "m25c": [1855, 86, 69.0],
        //   "f40c": [4743, 2014, 71.0],
        //   "m80c": [74, 54, 41.0],
        //   "f30c": [1634, 532, 72.0],
        //   "f15c": [1119, 564, 70.0],
        //   "m50c": [231, 152, 69.0],
        //   "f50c": [654, 355, 59.0],
        //   "f45c": [4152, 1617, 71.0],
        //   "m70c": [62, 30, 47.0],
        //   "m15c": [5130, 2106, 68.0],
        //   "f70c": [649, 378, 74.0],
        //   "m65c": [74, 29, 47.0],
        //   "m40c": [550, 400, 72.0],
        //   "m30c": [78, 58, 44.0],
        //   "f60c": [114, 62, 46.0],
        //   "f25c": [7215, 2196, 72.0],
        //   "f65c": [404, 253, 68.0],
        //   "f75c": [336, 270, 39.0],
        //   "m35c": [69, 27, 45.0]
        // },
        // "high": {
        //   "f40c": [4743, 2014, 71.0],
        //   "f30c": [1634, 532, 72.0],
        //   "f45c": [4152, 1617, 71.0],
        //   "f70c": [649, 378, 74.0],
        //   "m40c": [550, 400, 72.0],
        //   "f25c": [7215, 2196, 72.0]
        // },
        // "medium": {
        //   "f55c": [456, 79, 47.0],
        //   "m45c": [174, 62, 47.0],
        //   "m20c": [126, 74, 63.0],
        //   "m75c": [106, 39, 47.0],
        //   "f20c": [173, 129, 66.0],
        //   "m25c": [1855, 86, 69.0],
        //   "f15c": [1119, 564, 70.0],
        //   "m50c": [231, 152, 69.0],
        //   "f50c": [654, 355, 59.0],
        //   "m70c": [62, 30, 47.0],
        //   "m15c": [5130, 2106, 68.0],
        //   "m65c": [74, 29, 47.0],
        //   "f60c": [114, 62, 46.0],
        //   "f65c": [404, 253, 68.0],
        //   "m35c": [69, 27, 45.0]
        // },
        // "low": {
        //   "f35c": [194, 122, 42.0],
        //   "f80c": [193, 66, 44.0 ],
        //   "m55c": [68, 47, 42.0],
        //   "m60c": [153, 86, 41.0],
        //   "m80c": [74, 54, 41.0],
        //   "m30c": [78, 58, 44.0],
        //   "f75c": [336, 270, 39.0]
        // }
      }
      // arr_dat = rows
      /*JSON*/ arr_dat = [...globals?.context?.data_json_test?.campaigns?.audiences_table[group]] || []

      console.log("arr_all..............", arr_all)
    }
    return (
      <Layout header={{
        "title": "Audiences",
        "search": ""
      }}>
      <div className="_tempDummyHeader">Audiences</div>
      <Grid 
        content={[
          <Chart 
          text={{
            title: "",// "Impressions by Engagement",
            // subtitle: "",
            x_label: "Engagement",
            // x_flags: new Array(100),
            //y_label: ""
            y_flags: flags?.map((x) => x.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ","))// TODO: Shared at the top of chart.jsx/mapchart.jsx & Lib method instead (shared w/ <Insert/> & others)
          }}
          data={arr_all}
          mode={2}
        />,
        
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800,
            h: 400
          }}
        />
        <Grid
          content={[
            <TableSort 
              data={arr_dat}
              stick={true}// !!!!TODO: "All" to be automatically generated if selected
              undim={{"high": 4, "medium": 5, "low": 6}[group]}
              mode={ROTANA_DEMO_MODE ? 2 : 0}
            />
          ]}
          template={`
              0
              `}
          cellSize={{
            w: 800, 
            h: arr_dat?.length * 56 //800
          }}
        />

      </Layout>
)}