import {
  useRef,
  useState,
  useEffect
} from "react"

// Hooks
import {
  useFetch
} from "hooks/fetch"

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom"

// Contexts
import {
  ProvideGlobals
} from "contexts"

// Views
import {
  Home,
  Campaigns,
  AuthGateway,
  Catalog,
  Calculator,
  Help,
  Settings
} from "views"

import {
  Audiences,
  Locations,
  Category,
  IAB,
  Timing,
  Devices
} from "views/Campaigns"

// Util
import {
  ROUTE_PREFIX,
  ROUTE_LOGIN,
  ROUTE_HOME,
  ROUTE_CATALOG,
  ROUTE_CAMPAIGNS,
  ROUTE_CAMPAIGNS_01,
  ROUTE_CAMPAIGNS_02,
  ROUTE_CAMPAIGNS_03,
  ROUTE_CAMPAIGNS_04,
  ROUTE_CAMPAIGNS_05,
  ROUTE_CAMPAIGNS_06,
  ROUTE_HELP,
  ROUTE_SETTINGS,
  ROUTE_CALCULATOR,
  ROUTE_API// -> https://demo.gonucleus.io/api 
} from "util/Routes.const"

import { ENABLE_SIMPLE_AUTH } from "util/const"

import { API_NUCLEUS_BACKEND_USER } from "util/service.const"
import {
  SHOW_N_ENABLE_ALL,
  ENABLE_ROUTE_HOME,
  ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES,
  ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE,
  ENABLE_ROUTE_HELP,
  ENABLE_ROUTE_SETTINGS,
  ENABLE_CATALOG,
  ENABLE_ROUTE_CALCULATOR,
  ROTANA_DEMO_MODE
} from "util/const"

// Styling
import "./App.css"
import "./constants.css"
import { STATUS_FAILURE } from "util/Status"

// --- Static JSON example 1/2
// Static JSON file (until the final endpoint is ready)
// import local_json from `./_${ROTANA_DEMO_MODE ? "rotana" : "adverty"}.json`// ALT (can be dynamic w/ '${name}'): let JSON = require("_.json")
import local_json_adverty from "./_adverty.json"// ALT (can be dynamic w/ '${name}'): let JSON = require("_.json")
import local_json_rotana from "./_rotana.json"
// --- Static JSON example 1/2

const NUCLEUS_USER_KEY = "nucleus-user"

const globals = {
  theme: "light",
  data: { reach: {} },
  data_json_test: {}
}

// Component
export const App = () => {

  const [isReady, setReady] = useState()

  const tokenPre = localStorage.getItem(NUCLEUS_USER_KEY) ? JSON.parse(localStorage.getItem(NUCLEUS_USER_KEY)) : ""

  const [token, setToken] = useState(tokenPre)

  const renderTime = useRef(Date.now().toString().slice(0, 8)/* e.g. 17098824 (unix time w/o the trailing 0's [seconds and below] b/c timestamp always a rounded multiple of 3600) */)
  const fetch = useFetch()
  const loadSetup = async (/* token */) => {

    // Static data (w/ aggregated/fixed values)
    // let response = await fetch({
    //   url: `${ROUTE_API/*${publicKey}*/}/setup?latest=${renderTime}`
    //   /* token: ... */
    // })
    // --- Static JSON example 2/2 (until the 'setup' endpoint is ready)
    const location = await fetch({ url: `${ROUTE_API/*${publicKey}*/}/reach/list/locations`, token })
    const category = await fetch({ url: `${ROUTE_API/*${publicKey}*/}/reach/list/categories`, token })
    let response
    if ((location.status + category.status) === "successsuccess") {
      response = {"status": "success", "payload": {}}
      response["payload"] = (ROTANA_DEMO_MODE ? local_json_rotana : local_json_adverty)["john.doe@acme.com"/* dummy-token-test */]
      // response.payload.filters.locations = location?.payload?.locations
      // response.payload.filters.categories = category?.payload?.categories
    }
    // --- Static JSON example 2/2
    return new Promise((resolve, reject) => {// -> Explicit promise [vs equivalent b/c async 'return response?.payload'] for better resolve/reject management
      if (response?.status === "success") {
        resolve(response?.payload || {})
      } else {
        reject("Error: not all data was retrieved")
      }
    })
  }

  const checkIfAuthReady = async ({ token }) => {// -> FJ: Maybe no need b/c if the context is ready for its children then the token is ready too
    token && setToken(token)
    return token
  }

  const retrieveUser = async (token) => {
    if (!token) {
      // setIsLoading(false)
      return
    }
    try {
      const url = API_NUCLEUS_BACKEND_USER
      const response = await fetch({ url, token })

      const { status, payload } = response

      if (status === STATUS_FAILURE) {
        setToken(null)
        localStorage.removeItem(NUCLEUS_USER_KEY)
        window.location.href = `/${ROUTE_LOGIN}`
        return
      }

      localStorage.setItem(NUCLEUS_USER_KEY, JSON.stringify(token))

    } catch (error) {
      console.error("Could not get user")
    }
  }

  const loader = useRef(null)

  const removeLoader = () => {
    loader.current.classList.add("loader-wrapper--hide")
    setReady(true)
  }

  useEffect(() => {

    // DEV: 
    // localStorage.clear()// -> Start over (alt: 'removeItem()' [or 'key()' to read by index])
    // const cache = JSON.parse(localStorage.getItem("john.doe@acme.com"))
    // if (!cache || (cache?.latest < (renderTime.current - 36)/* One hour [+ 1 minute] has passed since the last BE snapshot */)) {

      loadSetup(/* token */).then((response) => {
        localStorage.setItem("john.doe@acme.com", JSON.stringify(response))

        // --- LOADER 1/2 ---
        setTimeout(() => {// -> TEMP: Fetchless lag simulation
          globals.data_json_test = Object.assign(globals.data_json_test, response)// -> Deep copy (otherwise 'context' won't be overriden [unless direct [sub]target as in 'globals.data_json_test.newkey = newvalue'])
          removeLoader()
        }, 3000)
        // --- LOADER 1/2 ---
      }).catch((error) => {
        // FIXME: Should include a proper error handling
        // Temporary solution (until the final endpoint is ready)
        globals.data_json_test = Object.assign(globals.data_json_test, (ROTANA_DEMO_MODE ? local_json_rotana : local_json_adverty)["john.doe@acme.com"/* dummy-token-test */])
        console.error(error)
        removeLoader()
      })
    // }
  }, [/* Once */])

  useEffect(() => {
    if (!token) {
      // setIsLoading(false)
      return
    }

    retrieveUser(token).then(() => {

      // TODO: Place in Auth-Context
      // TODO: Support for multiple users/tokens (both Google' and/or Nucleus')
    })
  }, [token])

  const PrivateRoute = ENABLE_SIMPLE_AUTH ? () => (token) ? <Outlet /> : <Navigate to={`/${ROUTE_LOGIN}`} /> : () => <Outlet />

  return (
    <ProvideGlobals globals={globals}>

      {/* --- LOADER 2/2 --- */}
      {/* TODO: Make timer configurable & convert into a component */}
      <div ref={loader} className={`loader-wrapper`}>
        <div className="loader">
          <div className="loader-bgs">
            <div className="loader-bg1"></div>
            <div className="loader-bg2"></div>
          </div>
          <div className="loader-fgs">
            <div className="loader-fg1"></div>
            <div className="loader-fg2"></div>
            <div className="loader-fg3"></div>
            <div className="loader-fg4"></div>
          </div>
        </div>
      </div>
      {/* --- LOADER 2/2 --- */}

      <BrowserRouter basename={ROUTE_PREFIX}>
        {ENABLE_CATALOG
          ? (
            <Routes>
              <Route path={`/${ROUTE_CATALOG}`} element={<Catalog ready={isReady} />} />
              <Route path="*" element={<Navigate to={`/${ROUTE_CATALOG}`} replace />} />
            </Routes>
          )
          :
          (
            <Routes>
              <Route path={`/${ROUTE_HOME}`} element={<Home />} />
              {ENABLE_SIMPLE_AUTH && <Route path={`/${ROUTE_LOGIN}`} element={<AuthGateway checkIfAuthReady={checkIfAuthReady} />} />}

              <Route element={<PrivateRoute />}>

                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HOME) && (
                  <Route path={`/${ROUTE_HOME}`} element={<Home ready={isReady} />} />
                )}

                <Route path={`/${ROUTE_CAMPAIGNS}`} element={<Campaigns ready={isReady}/>}/>
                <Route path={`/${ROUTE_CAMPAIGNS_01}`} element={<Audiences ready={isReady}/>}/>
                <Route path={`/${ROUTE_CAMPAIGNS_02}`} element={<Locations ready={isReady}/>}/>
                <Route path={`/${ROUTE_CAMPAIGNS_03}`} element={<Category ready={isReady}/>}/>
                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_INTEREST_CATEGORIES || ROTANA_DEMO_MODE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_04}`} element={<IAB ready={isReady}/>}/>
                )}
                <Route path={`/${ROUTE_CAMPAIGNS_05}`} element={<Timing ready={isReady} />} />
                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CAMPAIGNS_DEVICE_PERFORMANCE) && (
                  <Route path={`/${ROUTE_CAMPAIGNS_06}`} element={<Devices ready={isReady} />} />
                )}

                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_HELP) && (
                  <Route path={`/${ROUTE_HELP}`} element={<Help ready={isReady} />} />
                )}

                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_SETTINGS) && (
                  <Route path={`/${ROUTE_SETTINGS}`} element={<Settings ready={isReady} />} />
                )}

                {(SHOW_N_ENABLE_ALL || ENABLE_ROUTE_CALCULATOR) && (
                  <Route path={`/${ROUTE_CALCULATOR}`} element={<Calculator ready={isReady} />} />
                )}

                <Route path="*" element={<Navigate to={`/${ROUTE_CAMPAIGNS}`} replace />} />
              </Route>
            </Routes>
          )}
      </BrowserRouter>

    </ProvideGlobals>
  )
}
export default App