import { 
  useState
} from "react"

import {
  PopoverHelp,
  Tick,
  Insert,
  Counter,
  Heartbeat
} from "elements"

import "./TableCompare.css"

import { ReactComponent as IconTempPie } from "assets/icons_temp/pie.svg"
import { ReactComponent as IconTempDollar } from "assets/icons_temp/dollar.svg"
import { ReactComponent as IconTempEye } from "assets/icons_temp/eye.svg"
import { ReactComponent as IconTempTravel } from "assets/icons_temp/travel.svg"
import { ReactComponent as IconTempClock } from "assets/icons_temp/clock.svg"
import { ReactComponent as IconTempIos } from "assets/icons_temp/ios.svg"
import { ReactComponent as IconTempAndroid } from "assets/icons_temp/android.svg"
import { ReactComponent as IconTempScience } from "assets/icons_temp/science.svg"
import { ReactComponent as IconTempAction } from "assets/icons_temp/action.svg"
const icons = {
  "pie": <IconTempPie/>,
  "dollar": <IconTempDollar/>,
  "eye": <IconTempEye/>,
  "travel": <IconTempTravel/>,
  "clock": <IconTempClock/>,
  "ios": <IconTempIos/>,
  "android": <IconTempAndroid/>,
  "science": <IconTempScience/>,
  "action": <IconTempAction/>
}

export const TableCompare = ({data, data_, poly}) => {

  const [drawer, setDrawer] = useState([true, false, false, false, false])

  // if (!data.length) return 
  if (!data_?.header?.length) return 

// v2 - Static json file
return (
  <div className="tablecompare-wrapper">

    {/* Shared header */}
    <div className={"tablecompare__head-group"}>
      <div className={""}>
        <div className="tablecompare">
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className="orb-counter-test"><Counter value={124}/></div></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">{<Insert what={data_?.header?.[0]} size={40}/>}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">{<Insert what={data_?.header?.[1]} size={40}/>}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">{<Insert what={data_?.header?.[2]} size={40}/>}</div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Engagement</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Low</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Medium</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">High</div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining xxxxx"><Heartbeat size={30}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">0-30</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">31-70</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining">71-100</div></div>
          </div>
        </div>
      </div>
      {/* Subheader (this is a 'drawer' too) - TODO: Setup as a normal drawer & as a global one too (original foot design) */}
      <div className={"drawer " + (drawer[0] ? "drawer-0" : "drawer-0")}>
          {/* <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [!drawer[0], drawer[1], drawer[2], drawer[3], drawer[4]] : [true, false, false, false, false])}>aaa</div> */}
          <div className="tablecompare">
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["pie"]}</div>% of total</div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data_.key?.pct?.[0]?.[0] + "%"}/>}</div><div>{<Tick value={(((data_.key?.pct?.[0]?.[0] / data_.key?.pct?.[0]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data_.key?.pct?.[1]?.[0] + "%"}/>}</div><div>{<Tick value={(((data_.key?.pct?.[1]?.[0] / data_.key?.pct?.[1]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data_.key?.pct?.[2]?.[0] + "%"}/>}</div><div>{<Tick value={(((data_.key?.pct?.[2]?.[0] / data_.key?.pct?.[2]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
            </div>
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconsvg-wrapper-flip"}><IconTempDollar/></div>Average CPM</div></div>{/* TODO: Include the wrapper as part of a new SVG/icon component (perfect for padded halos) */}
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={"$" + data_.key?.cpm?.[0]?.[0]}/>}</div><div>{<Tick value={(((data_.key?.cpm?.[0]?.[0] / data_.key?.cpm?.[0]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={"$" + data_.key?.cpm?.[1]?.[0]}/>}</div><div>{<Tick value={(((data_.key?.cpm?.[1]?.[0] / data_.key?.cpm?.[1]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={"$" + data_.key?.cpm?.[2]?.[0]}/>}</div><div>{<Tick value={(((data_.key?.cpm?.[2]?.[0] / data_.key?.cpm?.[2]?.[1]) - 1) * 100) + "%"}/>}</div></div></div> 
            </div>
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}><IconTempEye/></div>Completion rate</div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data_.key?.crate?.[0]?.[0] + "%"}/>}</div><div>{<Tick value={(((data_.key?.crate?.[0]?.[0] / data_.key?.crate?.[0]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data_.key?.crate?.[1]?.[0] + "%"}/>}</div><div>{<Tick value={(((data_.key?.crate?.[1]?.[0] / data_.key?.crate?.[1]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data_.key?.crate?.[2]?.[0] + "%"}/>}</div><div>{<Tick value={(((data_.key?.crate?.[2]?.[0] / data_.key?.crate?.[2]?.[1]) - 1) * 100) + "%"}/>}</div></div></div>
            </div>
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
            </div>
          </div>
        </div>
      </div>

    {/* Key metrics */}
    <div className={"tablecompare__body-group " + (drawer[0] ? "" : "tablecompare__body-group--toggle")}>
      <div className={"drawer " + (drawer[1] ? "drawer-1" : "")}>
        <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], !drawer[1], drawer[2], drawer[3], drawer[4]] : [false, true, false, false, false])}>
          
          <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[1] ? "tablecompare__drawer-body--on" : "")}>
            <span>Key metrics</span>
            <div className={drawer[1] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
          </div>
          <div className="tablecompare">
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
            </div>
          </div>
        </div>
        <div className="tablecompare">
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Average eScore<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.escore?.[0]} size={22 + 0.001/* Rounding workaround */}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.escore?.[1]} size={22 + 0.001/* Rounding workaround */}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.escore?.[2]} size={22 + 0.001/* Rounding workaround */}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">% of total<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.pct?.[0]?.[0] + "%"}/>&nbsp;<Tick value={(((data_.key?.pct?.[0]?.[0] / data_.key?.pct?.[0]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.pct?.[1]?.[0] + "%"}/>&nbsp;<Tick value={(((data_.key?.pct?.[1]?.[0] / data_.key?.pct?.[1]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.pct?.[2]?.[0] + "%"}/>&nbsp;<Tick value={(((data_.key?.pct?.[2]?.[0] / data_.key?.pct?.[2]?.[1]) - 1) * 100) + "%"}/></div></div> 
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Impressions<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.impressions?.[0]?.[0] + ""}/>&nbsp;<Tick value={(((data_.key?.impressions?.[0]?.[0] / data_.key?.impressions?.[0]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.impressions?.[1]?.[0] + ""}/>&nbsp;<Tick value={(((data_.key?.impressions?.[1]?.[0] / data_.key?.impressions?.[1]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.impressions?.[2]?.[0] + ""}/>&nbsp;<Tick value={(((data_.key?.impressions?.[2]?.[0] / data_.key?.impressions?.[2]?.[1]) - 1) * 100) + "%"}/></div></div> 
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Average CPM<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"$" + data_.key?.cpm?.[0]?.[0]}/>&nbsp;<Tick value={(((data_.key?.cpm?.[0]?.[0] / data_.key?.cpm?.[0]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"$" + data_.key?.cpm?.[1]?.[0]}/>&nbsp;<Tick value={(((data_.key?.cpm?.[1]?.[0] / data_.key?.cpm?.[1]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"$" + data_.key?.cpm?.[2]?.[0]}/>&nbsp;<Tick value={(((data_.key?.cpm?.[2]?.[0] / data_.key?.cpm?.[2]?.[1]) - 1) * 100) + "%"}/></div></div> 
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Completion rate<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.crate?.[0]?.[0] + "%"}/>&nbsp;<Tick value={(((data_.key?.crate?.[0]?.[0] / data_.key?.crate?.[0]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.crate?.[1]?.[0] + "%"}/>&nbsp;<Tick value={(((data_.key?.crate?.[1]?.[0] / data_.key?.crate?.[1]?.[1]) - 1) * 100) + "%"}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.key?.crate?.[2]?.[0] + "%"}/>&nbsp;<Tick value={(((data_.key?.crate?.[2]?.[0] / data_.key?.crate?.[2]?.[1]) - 1) * 100) + "%"}/></div></div> 
          </div>
        </div>
      </div>

      {/* Ads top performers */}
      <div className={"drawer " + (drawer[2] ? "drawer-2" : "")}>
        <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], drawer[1], !drawer[2], drawer[3], drawer[4]] : [false, false, true, false, false])}>

          <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[2] ? "tablecompare__drawer-body--on" : "")}>
            <span>Ads top performers</span>
            <div className={drawer[2] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
          </div>
          <div className="tablecompare">
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
            </div>
          </div>

        </div>
        <div className="tablecompare">
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Audience<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.audience?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.audience?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.audience?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Location<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.location?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.location?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.location?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Content<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.content?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.content?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.content?.[2]} textlesstemp={null}/></div></div>
            {/* <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["travel"]}</div><Insert what={data_.ads?.content?.[0]} textlesstemp-null={true} heap={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["science"]}</div><Insert what={data_.ads?.content?.[1]} textlesstemp={true}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["action"]}</div><Insert what={data_.ads?.content?.[2]} textlesstemp={true}/></div></div> */}
            {/* <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["travel"]}</div>{data[11][0]}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["science"]}</div>{data[11][1]}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["action"]}</div>{data[11][2]}</div></div> */}
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Timing<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.timing?.[0]} textlesstemp={null}/>to<Insert what={(+(data_.ads?.timing?.[0])?.match(/\d+/) + 2) + (data_.ads?.timing?.[0])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.timing?.[1]} textlesstemp={null}/>to<Insert what={(+(data_.ads?.timing?.[1])?.match(/\d+/) + 2) + (data_.ads?.timing?.[1])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.timing?.[2]} textlesstemp={null}/>to<Insert what={(+(data_.ads?.timing?.[2])?.match(/\d+/) + 2) + (data_.ads?.timing?.[2])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
            {/* <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.timing?.[0]?.[0]} textlesstemp={null}/>to<Insert what={data_.ads?.timing?.[0]?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.timing?.[1]?.[0]} textlesstemp={null}/>to<Insert what={data_.ads?.timing?.[1]?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.timing?.[2]?.[0]} textlesstemp={null}/>to<Insert what={data_.ads?.timing?.[2]?.[1]} textlesstemp={null}/></div></div> */}
            {/* <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[12][0]}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[12][1]}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[12][2]}</div></div> */}
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Device<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.device?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.device?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.ads?.device?.[2]} textlesstemp={null}/></div></div>
            {/* <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[13][0]}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["android"]}</div>{data[13][1]}</div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[13][2]}</div></div> */}
          </div>
        </div>
      </div>

      {/* Subscriptions top performers */}
      <div className={"drawer " + (drawer[3] ? "drawer-3" : "")}>
        <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], drawer[1], drawer[2], !drawer[3], drawer[4]] : [false, false, false, true, false])}>
          
          <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[3] ? "tablecompare__drawer-body--on" : "")}>
            <span>Subscriptions top performers</span>
            <div className={drawer[3] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
          </div>
          <div className="tablecompare">
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
            </div>
          </div>

        </div>
        <div className="tablecompare">
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Audience<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.audience?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.audience?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.audience?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Location<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.location?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.location?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.location?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Content<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.content?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.content?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.content?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Timing<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.timing?.[0]} textlesstemp={null}/>to<Insert what={(+(data_.subscriptions?.timing?.[0])?.match(/\d+/) + 2) + (data_.subscriptions?.timing?.[0])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.timing?.[1]} textlesstemp={null}/>to<Insert what={(+(data_.subscriptions?.timing?.[1])?.match(/\d+/) + 2) + (data_.subscriptions?.timing?.[1])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.timing?.[2]} textlesstemp={null}/>to<Insert what={(+(data_.subscriptions?.timing?.[2])?.match(/\d+/) + 2) + (data_.subscriptions?.timing?.[2])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Device<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.device?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.device?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.device?.[2]} textlesstemp={null}/></div></div>
          </div>
        </div>
      </div>

      {/* Content top performers */}
      <div className={"drawer " + (drawer[4] ? "drawer-4" : "")}>
        <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], drawer[1], drawer[2], drawer[3], !drawer[4]] : [false, false, false, false, true])}>
          
          <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[4] ? "tablecompare__drawer-body--on" : "")}>
            <span>Content top performers</span>
            <div className={drawer[4] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
          </div>
          <div className="tablecompare">
            <div className="tablecompare__tr">
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
              <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
            </div>
          </div>

        </div>
        <div className="tablecompare">
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Audience<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.audience?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.audience?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.audience?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Location<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.location?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.location?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.location?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Content<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.content?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.content?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.content?.[2]} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Timing<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.timing?.[0]} textlesstemp={null}/>to<Insert what={(+(data_.content?.timing?.[0])?.match(/\d+/) + 2) + (data_.content?.timing?.[0])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.timing?.[1]} textlesstemp={null}/>to<Insert what={(+(data_.content?.timing?.[1])?.match(/\d+/) + 2) + (data_.content?.timing?.[1])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.content?.timing?.[2]} textlesstemp={null}/>to<Insert what={(+(data_.content?.timing?.[2])?.match(/\d+/) + 2) + (data_.content?.timing?.[2])?.replace(/\d+/, "")} textlesstemp={null}/></div></div>
          </div>
          <div className="tablecompare__tr">
            <div className="tablecompare__td"><div className="tablecompare__td__lining">Device<PopoverHelp data={{
              text: "",
              title: "Help title",
              details: "Help details"
            }}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.device?.[0]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.device?.[1]} textlesstemp={null}/></div></div>
            <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data_.subscriptions?.device?.[2]} textlesstemp={null}/></div></div>
          </div>

          
        </div>
      </div>
    </div>

    {/* Shared footer */}
    <div className="tablecompare__foot text-m" onClick={() => setDrawer([!drawer[0], drawer[0], false, false, false])}>
      {drawer[0] ? "See more" : "See less"}
      <div className={drawer[0] ? "tablecompare__drawer-arrow-dn" : "tablecompare__drawer-arrow-up"}></div>
    </div>
  </div>
)
}

// // v1 - Legacy backup

//   return (
//     <div className="tablecompare-wrapper">

//       {/* Shared header */}
//       <div className={"tablecompare__head-group"}>
//         <div className={""}>
//           <div className="tablecompare">
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className="orb-counter-test"><Counter value={124}/></div></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">{<Insert what={data[0]?.[0]} size={40}/>}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">{<Insert what={data[0]?.[1]} size={40}/>}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">{<Insert what={data[0]?.[2]} size={40}/>}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Engagement</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Low</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Medium</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">High</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining xxxxx"><Heartbeat size={30}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">0-30</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">31-70</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">71-100</div></div>
//             </div>
//           </div>
//         </div>
//         {/* Subheader (this is a 'drawer' too) - TODO: Setup as a normal drawer & as a global one too (original foot design) */}
//         <div className={"drawer " + (drawer[0] ? "drawer-0" : "drawer-0")}>
//             {/* <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [!drawer[0], drawer[1], drawer[2], drawer[3], drawer[4]] : [true, false, false, false, false])}>aaa</div> */}
//             <div className="tablecompare">
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["pie"]}</div>% of total</div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[1]?.[0]?.[0]}/>}</div><div>{<Tick value={data[1]?.[0]?.[1] + "%"}/>}</div></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[1]?.[1]?.[0]}/>}</div><div>{<Tick value={data[1]?.[1]?.[1] + "%"}/>}</div></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[1]?.[2]?.[0]}/>}</div><div>{<Tick value={data[1]?.[2]?.[1] + "%"}/>}</div></div></div>
//               </div>
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconsvg-wrapper-flip"}><IconTempDollar/></div>Average CPM</div></div>{/* TODO: Include the wrapper as part of a new SVG/icon component (perfect for padded halos) */}
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[2]?.[0]?.[0]}/>}</div><div>{<Tick value={data[2]?.[0]?.[1] + "%"}/>}</div></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[2]?.[1]?.[0]}/>}</div><div>{<Tick value={data[2]?.[1]?.[1] + "%"}/>}</div></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[2]?.[2]?.[0]}/>}</div><div>{<Tick value={data[2]?.[2]?.[1] + "%"}/>}</div></div></div>
//               </div>
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}><IconTempEye/></div>Completion rate</div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[3]?.[0]?.[0]}/>}</div><div>{<Tick value={data[3]?.[0]?.[1] + "%"}/>}</div></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[3]?.[1]?.[0]}/>}</div><div>{<Tick value={data[3]?.[1]?.[1] + "%"}/>}</div></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"><div>{<Counter value={data[3]?.[2]?.[0]}/>}</div><div>{<Tick value={data[3]?.[2]?.[1] + "%"}/>}</div></div></div>
//               </div>
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//               </div>
//             </div>
//           </div>
//         </div>

//       {/* Key metrics */}
//       <div className={"tablecompare__body-group " + (drawer[0] ? "" : "tablecompare__body-group--toggle")}>
//         <div className={"drawer " + (drawer[1] ? "drawer-1" : "")}>
//           <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], !drawer[1], drawer[2], drawer[3], drawer[4]] : [false, true, false, false, false])}>
            
//             <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[1] ? "tablecompare__drawer-body--on" : "")}>
//               <span>Key metrics</span>
//               <div className={drawer[1] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
//             </div>
//             <div className="tablecompare">
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//               </div>
//             </div>
//           </div>
//           <div className="tablecompare">
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Average eScore<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[4][0]} size={22.001}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[4][1]} size={22.001}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[4][2]} size={22.001}/></div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">% of total<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[5][0][0]}/>&nbsp;<Tick value={data[5][0][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[5][1][0]}/>&nbsp;<Tick value={data[5][1][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[5][2][0]}/>&nbsp;<Tick value={data[5][2][1] + "%"}/></div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Impressions<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[6][0][0]}/>&nbsp;<Tick value={data[6][0][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[6][1][0]}/>&nbsp;<Tick value={data[6][1][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[6][2][0]}/>&nbsp;<Tick value={data[6][2][1] + "%"}/></div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Average CPM<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[7][0][0]}/>&nbsp;<Tick value={data[7][0][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[7][1][0]}/>&nbsp;<Tick value={data[7][1][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[7][2][0]}/>&nbsp;<Tick value={data[7][2][1] + "%"}/></div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Completion rate<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[8][0][0]}/>&nbsp;<Tick value={data[8][0][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[8][1][0]}/>&nbsp;<Tick value={data[8][1][1] + "%"}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={data[8][2][0]}/>&nbsp;<Tick value={data[8][2][1] + "%"}/></div></div>
//             </div>
//           </div>
//         </div>

//         {/* Ads top performers */}
//         <div className={"drawer " + (drawer[2] ? "drawer-2" : "")}>
//           <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], drawer[1], !drawer[2], drawer[3], drawer[4]] : [false, false, true, false, false])}>
            


//             <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[2] ? "tablecompare__drawer-body--on" : "")}>
//               <span>Ads top performers</span>
//               <div className={drawer[2] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
//             </div>
//             <div className="tablecompare">
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//               </div>
//             </div>


//           </div>
//           <div className="tablecompare">
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Audience<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"f20c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[9][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"f35c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[9][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"m45c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[9][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Location<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"DE"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[10][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"EG"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[10][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"SA"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[10][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Content<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["travel"]}</div>{data[11][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["science"]}</div>{data[11][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["action"]}</div>{data[11][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Timing<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[12][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[12][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[12][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Device<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[13][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["android"]}</div>{data[13][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[13][2]}</div></div>
//             </div>
//           </div>
//         </div>

//         {/* Subscriptions top performers */}
//         <div className={"drawer " + (drawer[3] ? "drawer-3" : "")}>
//           <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], drawer[1], drawer[2], !drawer[3], drawer[4]] : [false, false, false, true, false])}>
            


//             <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[3] ? "tablecompare__drawer-body--on" : "")}>
//               <span>Subscriptions top performers</span>
//               <div className={drawer[3] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
//             </div>
//             <div className="tablecompare">
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//               </div>
//             </div>

//           </div>
//           <div className="tablecompare">
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Audience<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"f20c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[14][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"f35c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[14][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"m45c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[14][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Location<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"KW"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[15][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"US"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[15][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"SA"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[15][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Content<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["travel"]}</div>{data[16][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["science"]}</div>{data[16][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["action"]}</div>{data[16][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Timing<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[17][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[17][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[17][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Device<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[18][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["android"]}</div>{data[18][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[18][2]}</div></div>
//             </div>
//           </div>
//         </div>

//         {/* Content top performers */}
//         <div className={"drawer " + (drawer[4] ? "drawer-4" : "")}>
//           <div className="tablecompare__toggler" onClick={() => setDrawer(poly ? [drawer[0], drawer[1], drawer[2], drawer[3], !drawer[4]] : [false, false, false, false, true])}>
            



//             <div className={"tablecompare__toggler__overlay text-m-b " + (drawer[4] ? "tablecompare__drawer-body--on" : "")}>
//               <span>Content top performers</span>
//               <div className={drawer[4] ? "tablecompare__drawer-arrow-up" : "tablecompare__drawer-arrow-dn"}></div>
//             </div>
//             <div className="tablecompare">
//               <div className="tablecompare__tr">
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//                 <div className="tablecompare__td"><div className="tablecompare__td__lining"></div></div>
//               </div>
//             </div>


//           </div>
//           <div className="tablecompare">
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Audience<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"f20c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[19][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"f35c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[19][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"m45c"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[19][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Location<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"DE"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[20][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"MA"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[20][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><Insert what={"GB"} textlesstemp={true}/>&nbsp;&nbsp;&nbsp;{data[20][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Content<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["travel"]}</div>{data[21][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["science"]}</div>{data[21][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["action"]}</div>{data[21][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Timing<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[22][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[22][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper"}>{icons["clock"]}</div>{data[22][2]}</div></div>
//             </div>
//             <div className="tablecompare__tr">
//               <div className="tablecompare__td"><div className="tablecompare__td__lining">Device<PopoverHelp data={{
//                 text: "",
//                 title: "Help title",
//                 details: "Help details"
//               }}/></div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[23][0]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["android"]}</div>{data[23][1]}</div></div>
//               <div className="tablecompare__td"><div className="tablecompare__td__lining"><div className={"tempiconsvg-wrapper tempiconssvg-wrapper-fill"}>{icons["ios"]}</div>{data[23][2]}</div></div>
//             </div>

            
//           </div>
//         </div>
//       </div>

//       {/* Shared footer */}
//       <div className="tablecompare__foot text-m" onClick={() => setDrawer([!drawer[0], drawer[0], false, false, false])}>
//         {drawer[0] ? "See more" : "See less"}
//         <div className={drawer[0] ? "tablecompare__drawer-arrow-dn" : "tablecompare__drawer-arrow-up"}></div>
//       </div>
//     </div>
//   )
// }