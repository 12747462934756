// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
    Grid,
    Layout
} from "containers"

// Components
import {
  TableSort,
  Chart
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Content-wide styling
import "./Category.css"

// Content assembly
export const Category = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // Unpack engagement grouping settings
  // const group = globals?.context?.selected?.[0] || "_"
  // const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"

  // Dummy data loader (snow owl demo only)
  // TODO: Merge all data (ugly last-minute workaround)
  // const arr_all_obj = {
  //   "_": {
  //     "action": [14215, 1.03, [1.17, 1.02, 0.92]],
  //     "bike": [109933, 7.97, [5.55, 7.60, 11.48]],
  //     "casual": [101389, 7.35, [9.51, 7.23, 5.54]],
  //     "minecraft": [95771, 6.94, [2.63, 7.38, 9.94]],
  //     "other_category": [124225, 9.00, [10.85, 8.85, 7.60]],
  //     "puzzle": [64316, 4.66, [8.42, 4.46, 1.49]],
  //     "racing": [83753, 6.07, [8.62, 5.60, 4.93]],
  //     "shooter": [7864, 0.57, [0.48, 0.58, 0.62]],
  //     "simulator": [4268, 0.31, [0.42, 0.31, 0.20]],
  //     "single_player": [54651, 3.96, [2.81, 4.66, 3.00]],
  //     "sport": [66443, 4.81, [11.18, 3.79, 1.51]],
  //     "unknown": [653260, 47.33, [38.35, 48.52, 52.76]]
  //   },
  //   "high": {
  //     "action": [14215, 1.03, [1.17, 1.02, 0.92]],
  //     "bike": [109933, 7.97, [5.55, 7.60, 11.48]],
  //     "casual": [101389, 7.35, [9.51, 7.23, 5.54]],
  //     "minecraft": [95771, 6.94, [2.63, 7.38, 9.94]],
  //     "other_category": [124225, 9.00, [10.85, 8.85, 7.60]],
  //     "puzzle": [64316, 4.66, [8.42, 4.46, 1.49]],
  //     "racing": [83753, 6.07, [8.62, 5.60, 4.93]],
  //     "shooter": [7864, 0.57, [0.48, 0.58, 0.62]],
  //     "simulator": [4268, 0.31, [0.42, 0.31, 0.20]],
  //     "single_player": [54651, 3.96, [2.81, 4.66, 3.00]],
  //     "sport": [66443, 4.81, [11.18, 3.79, 1.51]],
  //     "unknown": [653260, 47.33, [38.35, 48.52, 52.76]]
  //   },
  //   "medium": {
  //     "action": [14215, 1.03, [1.17, 1.02, 0.92]],
  //     "bike": [109933, 7.97, [5.55, 7.60, 11.48]],
  //     "casual": [101389, 7.35, [9.51, 7.23, 5.54]],
  //     "minecraft": [95771, 6.94, [2.63, 7.38, 9.94]],
  //     "other_category": [124225, 9.00, [10.85, 8.85, 7.60]],
  //     "puzzle": [64316, 4.66, [8.42, 4.46, 1.49]],
  //     "racing": [83753, 6.07, [8.62, 5.60, 4.93]],
  //     "shooter": [7864, 0.57, [0.48, 0.58, 0.62]],
  //     "simulator": [4268, 0.31, [0.42, 0.31, 0.20]],
  //     "single_player": [54651, 3.96, [2.81, 4.66, 3.00]],
  //     "sport": [66443, 4.81, [11.18, 3.79, 1.51]],
  //     "unknown": [653260, 47.33, [38.35, 48.52, 52.76]]
  //   },
  //   "low": {
  //     "action": [14215, 1.03, [1.17, 1.02, 0.92]],
  //     "bike": [109933, 7.97, [5.55, 7.60, 11.48]],
  //     "casual": [101389, 7.35, [9.51, 7.23, 5.54]],
  //     "minecraft": [95771, 6.94, [2.63, 7.38, 9.94]],
  //     "other_category": [124225, 9.00, [10.85, 8.85, 7.60]],
  //     "puzzle": [64316, 4.66, [8.42, 4.46, 1.49]],
  //     "racing": [83753, 6.07, [8.62, 5.60, 4.93]],
  //     "shooter": [7864, 0.57, [0.48, 0.58, 0.62]],
  //     "simulator": [4268, 0.31, [0.42, 0.31, 0.20]],
  //     "single_player": [54651, 3.96, [2.81, 4.66, 3.00]],
  //     "sport": [66443, 4.81, [11.18, 3.79, 1.51]],
  //     "unknown": [653260, 47.33, [38.35, 48.52, 52.76]]
  //   }
  // }[group] 

  // TODO: Prevent making this mapping redundant (we should either use "Foo Bar" formats at all times for labeling or have some kind of external mapping file for all components)
  const names = [{
    // "all_genres": "All Genres",
    // "action": "Action",
    // "drama": "Drama",
    // "horror": "Horror",
    // "documentaries": "Documentaries",
    // "comedies": "Comedies",
    // "romance": "Romance",
    // "sci-fi": "Sci-Fi",
    // "sports": "Sports",

    "all_content": "All content",

    "action": "Action",
    "bike": "Bike",
    "casual": "Casual",
    "minecraft": "Minecraft",
    "other_category": "Other category",
    "puzzle": "Puzzle",
    "racing": "Racing",
    "shooter": "Shooter",
    "simulator": "Simulator",
    "single_player": "Single player",
    "sport": "Sport",
    "unknown": "Unknown"
  },
  {
    "all_categories": "Categories",
    "unknown_category": "Unknown Category",
    "channel_overview": "Channel Overview",
    "rotana_khalijea_hd": "Rotana Khalijea HD",
    "rotana_cinema_ksa": "Rotana Cinema KSA",
    "rotana_cinema_masr": "Rotana Cinema MASR",
    "homepage": "Homepage",
    "rotana_classic": "Rotana Classic",
    "rotana_comedy": "Rotana Comedy",
    "lbc": "LBC",
    "rotana_drama": "Rotana Drama",
    "rotana_clip": "Rotana Clip",
    "dahk_wa_bass": "Dahk Wa Bass",
    "romance": "Romance",
    "al_resalah": "Al Resalah",
    "el_ressalah": "El Ressalah",
    "sayedat_alshasha": "Sayedat Alshasha",
    "al_resalah_international": "Al Resalah International"    
  }][ROTANA_DEMO_MODE ? 1 : 0]


  // const rows = {
  //   "_": [
  //     // --- Head
  //     ["Category", 0, 0, 0, 0, 0, 0, 0, 0],
      
  //     // --- Body
  //     ["Action", 39, 14215, 1.03, 1.17, 1.02, 0.92, 12.72, 45.60],
  //     ["Bike", 31, 109933, 7.97, 5.55, 7.60, 11.48, 8.74, 2.34],
  //     ["Casual", 40, 101389, 7.35, 9.51, 7.23, 5.54, 11.96, 48.36],
  //     ["Minecraft", 29, 95771, 6.94, 2.63, 7.38, 9.94, 15.04, 23.41],
  //     ["Other category", 40, 124225, 9.00, 10.85, 8.85, 7.60, 12.84, 52.81],
  //     ["Puzzle", 48, 64316, 4.66, 8.42, 4.46, 1.49, 9.33, 75.08],
  //     ["Racing", 39, 83753, 6.07, 8.62, 5.60, 4.93, 14.07, 24.23],
  //     ["Shooter", 35, 7864, 0.57, 0.48, 0.58, 0.62, 12.92, 41.00],
  //     ["Simulator", 42, 4268, 0.31, 0.42, 0.31, 0.20, 8.67, 31.09],
  //     ["Single player", 37, 54651, 3.96, 2.81, 4.66, 3.00, 7.76, 58.56],
  //     ["Sport", 52, 66443, 4.81, 11.18, 3.79, 1.51, 10.83, 64.84],
  //     ["Unknown", 35, 653260, 47.33, 38.35, 48.52, 52.76, 13.43, 43.11]
  //   ],
  //   "high": [
  //     // --- Head
  //     ["Category", 0, 0, 0, 0, 0, 0, 0, 0],
      
  //     // --- Body
  //     ["Action", 39, 14215, 1.03, 1.17, 1.02, 0.92, 12.72, 45.60],
  //     ["Bike", 31, 109933, 7.97, 5.55, 7.60, 11.48, 8.74, 2.34],
  //     ["Casual", 40, 101389, 7.35, 9.51, 7.23, 5.54, 11.96, 48.36],
  //     ["Minecraft", 29, 95771, 6.94, 2.63, 7.38, 9.94, 15.04, 23.41],
  //     ["Other category", 40, 124225, 9.00, 10.85, 8.85, 7.60, 12.84, 52.81],
  //     ["Puzzle", 48, 64316, 4.66, 8.42, 4.46, 1.49, 9.33, 75.08],
  //     ["Racing", 39, 83753, 6.07, 8.62, 5.60, 4.93, 14.07, 24.23],
  //     ["Shooter", 35, 7864, 0.57, 0.48, 0.58, 0.62, 12.92, 41.00],
  //     ["Simulator", 42, 4268, 0.31, 0.42, 0.31, 0.20, 8.67, 31.09],
  //     ["Single player", 37, 54651, 3.96, 2.81, 4.66, 3.00, 7.76, 58.56],
  //     ["Sport", 52, 66443, 4.81, 11.18, 3.79, 1.51, 10.83, 64.84],
  //     ["Unknown", 35, 653260, 47.33, 38.35, 48.52, 52.76, 13.43, 43.11]
  //   ],
  //   "medium": [
  //     // --- Head
  //     ["Category", 0, 0, 0, 0, 0, 0, 0, 0],
      
  //     // --- Body
  //     ["Action", 39, 14215, 1.03, 1.17, 1.02, 0.92, 12.72, 45.60],
  //     ["Bike", 31, 109933, 7.97, 5.55, 7.60, 11.48, 8.74, 2.34],
  //     ["Casual", 40, 101389, 7.35, 9.51, 7.23, 5.54, 11.96, 48.36],
  //     ["Minecraft", 29, 95771, 6.94, 2.63, 7.38, 9.94, 15.04, 23.41],
  //     ["Other category", 40, 124225, 9.00, 10.85, 8.85, 7.60, 12.84, 52.81],
  //     ["Puzzle", 48, 64316, 4.66, 8.42, 4.46, 1.49, 9.33, 75.08],
  //     ["Racing", 39, 83753, 6.07, 8.62, 5.60, 4.93, 14.07, 24.23],
  //     ["Shooter", 35, 7864, 0.57, 0.48, 0.58, 0.62, 12.92, 41.00],
  //     ["Simulator", 42, 4268, 0.31, 0.42, 0.31, 0.20, 8.67, 31.09],
  //     ["Single player", 37, 54651, 3.96, 2.81, 4.66, 3.00, 7.76, 58.56],
  //     ["Sport", 52, 66443, 4.81, 11.18, 3.79, 1.51, 10.83, 64.84],
  //     ["Unknown", 35, 653260, 47.33, 38.35, 48.52, 52.76, 13.43, 43.11]
  //   ],
  //   "low": [
  //     // --- Head
  //     ["Category", 0, 0, 0, 0, 0, 0, 0, 0],
      
  //     // --- Body
  //     ["Action", 39, 14215, 1.03, 1.17, 1.02, 0.92, 12.72, 45.60],
  //     ["Bike", 31, 109933, 7.97, 5.55, 7.60, 11.48, 8.74, 2.34],
  //     ["Casual", 40, 101389, 7.35, 9.51, 7.23, 5.54, 11.96, 48.36],
  //     ["Minecraft", 29, 95771, 6.94, 2.63, 7.38, 9.94, 15.04, 23.41],
  //     ["Other category", 40, 124225, 9.00, 10.85, 8.85, 7.60, 12.84, 52.81],
  //     ["Puzzle", 48, 64316, 4.66, 8.42, 4.46, 1.49, 9.33, 75.08],
  //     ["Racing", 39, 83753, 6.07, 8.62, 5.60, 4.93, 14.07, 24.23],
  //     ["Shooter", 35, 7864, 0.57, 0.48, 0.58, 0.62, 12.92, 41.00],
  //     ["Simulator", 42, 4268, 0.31, 0.42, 0.31, 0.20, 8.67, 31.09],
  //     ["Single player", 37, 54651, 3.96, 2.81, 4.66, 3.00, 7.76, 58.56],
  //     ["Sport", 52, 66443, 4.81, 11.18, 3.79, 1.51, 10.83, 64.84],
  //     ["Unknown", 35, 653260, 47.33, 38.35, 48.52, 52.76, 13.43, 43.11]
  //   ]
  // }[group]

  /* First access */
  const picks = globals.get("checkboxed") || (
    ROTANA_DEMO_MODE 
    ?
    ["unknown_category", "channel_overview", "rotana_khalijea_hd", "rotana_cinema_ksa", "rotana_cinema_masr", "homepage", "rotana_classic", "rotana_comedy", "lbc", "rotana_drama", "rotana_clip", "dahk_wa_bass", "al_resalah", "el_ressalah", "sayedat_alshasha", "al_resalah_international"]
    :
    ["action", "bike", "casual", "minecraft", "other_category", "puzzle", "racing", "shooter", "simulator", "single_player", "sport", "unknown"]
  )

  let txt_sel = []
  let arr_sel = []
  let arr_dat = []
  let flags = []//[200000, 400000, 600000, 800000, 1000000]
  if (ready) {
    /*JSON*/const arr_all_obj = globals?.context?.data_json_test?.campaigns?.categories_chart?.[group]
    /*JSON*/flags = globals?.context?.data_json_test?.campaigns?.categories_chart_flags 
    for (let i = 0; i < picks?.length; i++) {
      let text = names?.[picks?.[i].replace("checkboxgroup-", "")]
      txt_sel.push(text)
      arr_sel.push(arr_all_obj[text.toLowerCase().replaceAll(" ", "_")])
    }
    //arr_dat = rows
    arr_dat = [...globals?.context?.data_json_test?.campaigns?.categories_table?.[group]] 

    // console.log("SEL", globals?.context?.data_json_test?.campaigns?.categories_chart?.[group])
    console.log("TIMES", arr_dat)

  }
  // --- Shared ---

  return (
    <Layout header={{
      "title": "Categories",
      "search": ""
    }}>
    
      {/* TODO: Make header component out of this */}
      {/* <div className={`text-xl _tempDummyHeader _tempDummyHeader--${group ? "link" : ""}`}>
        <span onClick={() => setGroup()}>Categories </span>
        {group &&
        <>
          <span> → </span>
          <span>{group + " engagement groups"}</span>
        </>}
      </div> */}

    <div className="_tempDummyHeader">Categories</div>

    <Grid
        content={[
          ready/* Here too to animate bars on 1st load */ ? <Chart 
          text={{
            // title: "Revenue Impact",
            // subtitle: "Statistics",
            //x_label: "",
            x_flags: txt_sel,
            y_label: ROTANA_DEMO_MODE ? "Visits" : "Total reach", 
            y_flags: flags.map((x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))// TODO: Shared at the top of chart.jsx/mapchart.jsx & Lib method instead (shared w/ <Insert/> & others)
          }}
          data={arr_sel}
          mode={7}
        /> : "",
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 400
        }}
      />
      <Grid
        content={[
          <TableSort 
            data={arr_dat}
            stick={true}
            undim={{"high": 4, "medium": 5, "low": 6}[group]}
            mode={ROTANA_DEMO_MODE ? 2 : 0}
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: arr_dat?.length * 56 //680
        }}
      />

    </Layout>
)}