// Containers
import { 
  Layout,
  Grid
} from "containers"

// Components
import { 
  Topbar,
  Wheel,
  ChartBars,
  TableCompare
} from "components"

// Contexts
import {
  useGlobals
} from "contexts"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Home-wide styling
import "./Campaigns.css"

// Campaigns assembly
export const Campaigns = ({ready}) => {
  
  const globals = useGlobals()

  // const [expanded, setExpanded] = useState(false)
  // useEffect(() => {
  //   if (ready) {
  //     setLoading(undefined)// -> Use 'reRender(Date.now())' instead to [auto]get results after ready
  //   }
  // }, [ready])

  // const cols = [

  //   // Preview
  //   [["f20c", "m25c", "m35c"], ["f60c", "m30c", "f25c"], ["m45c", "f40c", "m80c"]],
  //   [["17%", 13], ["61%", 7], ["22%", -7]],
  //   [["$1.6", 13], ["$2.1", -7], ["$2.6", 7]],
  //   [["52.3%", 13], ["61.1%", 7], ["72.4%", 7]],

  //   // Key metrics
  //   [10, 37, 60],
  //   [["17%", 7], ["61%", 13], ["22%", -7]],
  //   [["1784", 7], ["65126", 7], ["30322", 13]],
  //   [["$1.6", 7], ["$2.1", -7], ["$2.6", 13]],
  //   [["52.3%", 7], ["61.1%", 13], ["72.4%", 13]],
    
  //   // Ads top performers
  //   ["Claire McCormick", "Julia Jones", "Franc Wilde"],
  //   ["Berlin, Germany", "Cairo, Egypt", "Riyadh, Saudi Arabia"],
  //   ["Travel", "Science", "Action"],
  //   ["10:00 - 12:00", "15:00 - 18:00", "17:00 - 21:00"],
  //   ["iPhone X", "Samsung S3", "iPhone 13"],

  //   // Subscriptions top performers
  //   ["Claire McCormick", "Julia Jones", "Franc Wilde"],
  //   ["Kuwait, Kuwait", "Los Angeles, United States", "Riyadh, Saudi Arabia"],
  //   ["Travel", "Science", "Action"],
  //   ["10:00 - 12:00", "15:00 - 18:00", "17:00 - 21:00"],
  //   ["iPhone X", "Samsung S3", "iPhone 13"],

  //   // Content top performers
  //   ["Claire McCormick", "Julia Jones", "Franc Wilde"],
  //   ["Berlin, Germany", "Casablanca, Morocco", "London, United Kingdom"],
  //   ["Travel", "Science", "Action"],
  //   ["10:00 - 12:00", "15:00 - 18:00", "17:00 - 21:00"],
  //   ["iPhone X", "Samsung S3", "iPhone 13"],
  // ]
  
  let arr_arc = []
  let arr_bar = []
  let arr_top = new Array(4).fill([0, 0])
  let arr_dat = []
  let arr_dat_= []
  if (ready) {

    // v1 - Legacy
    //arr_arc = [[37, -1]/*[35, 0]*/]
    // arr_bar = Array(365 * 2/* Years of dummy data */).fill(0).map(() => Math.floor(Math.random() * (42/*max*/ - 32/*min*/) + 32))/* -> Pattern repeater for demo-ing purposes */
    // arr_top = [[360823, 360823], [838381, 838381], [24.68, 24.68], [42.16, 42.16]]
    // arr_dat = cols

    // v2 - Static json file
    const data = globals?.context?.data_json_test?.overview
    arr_bar = data.scores
    arr_arc = [[arr_bar[0], -1]/*, [35, 0]*/]
    const cards = data.cards
    arr_top = [cards.reach, cards.sessions, cards.session_len, cards.viz_pct]
    arr_dat_= data.table
  }

  // if (!arr_dat?.length) return

  return (
    <Layout header={{
      "title": "Ads Overview",
      "search": ""
    }}>

      {/* TODO: Include with real data */}
      <div className="_tempDummyHeader">Overview</div>
     
      <Grid
        content={[
          <Wheel 
            text={{
              title: "Heartbeat",
              subtitle: ""
            }}
            data={arr_arc}
            mode={1}
          />,

          ready/* Here too to animate bars on 1st load */ ? 
          <ChartBars 
            text={{
              title: "History",
              // subtitle: "Your audience engagement over time"
            }}
            data={arr_bar}
            disabled={!!ROTANA_DEMO_MODE}
          /> : ""
        ]}
        template={`
            0 1 1
            `}
        cellSize={{
          w: 255,
          h: 320
        }}
      />

      {ROTANA_DEMO_MODE 
      ? 
      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Total sessions",
                "data": arr_top[1],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Unique visits",
                "data": arr_top[0],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg session length",
                "data": arr_top[2],
                "unit": "s"
              }
            ]}
          />
        ]}
        template={`
            0 1 2
            `}
        cellSize={{
          w: 146,
          h: 80
        }}
      />
      :
      <Grid
        content={[
          <Topbar 
            data={[
              {
                "name": "Reach",
                "data": arr_top[0],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Total sessions",
                "data": arr_top[1],
                "unit": ""
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Avg session length",
                "data": arr_top[2],
                "unit": "s"
              }
            ]}
          />,
          <Topbar 
            data={[
              {
                "name": "Viewability percentage",
                "data": arr_top[3],
                "unit": "%"
              }
            ]}
          />
        ]}
        template={`
            0 1 2 3
            `}
        cellSize={{
          w: 146,
          h: 80
        }}
      />}

      {ROTANA_DEMO_MODE ? "" : <Grid
        content={[
          <TableCompare 
            data={arr_dat}
            data_={arr_dat_}
            
            // -------------------
            poly={false}
            // -------------------
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800,
          h: 1//Ignored -> Legacy: 'expanded ? 800 : 320'
        }}

        flat={true}// ???
        
      />}
    </Layout>
)}