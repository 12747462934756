const { 
  REACT_APP_PATH,
  REACT_APP_NUCLEUS_API
} = process.env

// Base
// export const ROUTE_SIGNUP = "signup"
export const ROUTE_LOGIN = "login"
// export const ROUTE_RESET = "reset"

export const ROUTE_HOME = "home"

export const ROUTE_CONTENT = "content"

export const ROUTE_CATALOG = "catalog"

export const ROUTE_CAMPAIGNS = "campaigns"
export const ROUTE_CAMPAIGNS_00 = "campaigns/00"
export const ROUTE_CAMPAIGNS_01 = "campaigns/01"
export const ROUTE_CAMPAIGNS_02 = "campaigns/02"
export const ROUTE_CAMPAIGNS_03 = "campaigns/03"
export const ROUTE_CAMPAIGNS_04 = "campaigns/04"
export const ROUTE_CAMPAIGNS_05 = "campaigns/05"
export const ROUTE_CAMPAIGNS_06 = "campaigns/06"

export const ROUTE_SUBSCRIPTIONS = "subscriptions"
export const ROUTE_SUBSCRIPTIONS_00 = "subscriptions/00"
export const ROUTE_SUBSCRIPTIONS_01 = "subscriptions/01"
export const ROUTE_SUBSCRIPTIONS_02 = "subscriptions/02"
export const ROUTE_SUBSCRIPTIONS_03 = "subscriptions/03"
export const ROUTE_SUBSCRIPTIONS_04 = "subscriptions/04"
export const ROUTE_SUBSCRIPTIONS_05 = "subscriptions/05"
export const ROUTE_SUBSCRIPTIONS_06 = "subscriptions/06"

export const ROUTE_VIDEO = "video"
export const ROUTE_HELP = "help"
export const ROUTE_SETTINGS = "settings"
export const ROUTE_CALCULATOR = "calculator"

// Util
export const ROUTE_PREFIX = REACT_APP_PATH || ""
export const ROUTE_API = REACT_APP_NUCLEUS_API || ""