import { useNavigate } from "react-router-dom"
import { googleLogout } from "@react-oauth/google"

// Util
import { ROUTE_LOGIN } from "util/Routes.const"

// Icons
import { ReactComponent as LogoutIcon } from "assets/icons_mini/logout.svg"

import AvatarPic from "assets/_temp/avatar.png"

// Styling
import "./Avatar.css"
// import { useEffect } from "react"

export const Avatar = ({ userCard, wide }) => {
    const navigate = useNavigate()
    const onLogout = () => {
        googleLogout()
        localStorage.removeItem("nucleus-user")
        navigate(`/${ROUTE_LOGIN}`)
    }

    // TODO: Full support for userCard.picture && userCard.email_verified
    return (
        <div className="avatar">
            <div className={`avatar__picture ${false && userCard?.avatar ? "" : "_avatar__picture-less"}`} onClick={onLogout}>
                <LogoutIcon className="avatar__picture__icon"/>
                <img src={AvatarPic} alt={userCard?.name}/>
            </div>
            {wide 
            ?
            <div className="avatar__details">
                <span className="text-m">
                    <b>{

                    // Loop through each element of the array and capitalize the first letter
                    (() => {
                    const words = userCard?.name?.split(/[\d\W_]+/)
                    if (words) {
                        for (var i = 0; i < words.length; i++) {
                            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
                        }
                        return words.join(" ")
                    } else {
                        return "Unknown Name"
                    }
                })()
                }</b>
                </span>
                <span className="text-s">{userCard?.email || "Unknown email"}</span>
                {/* <span>&nbsp;</span> */}
            </div> 
            :
            ""}
            <span className="avatar__prompt text-m">&nbsp;</span>
        </div>
    )
}