import { 
	useEffect,
	useState,
	useRef
 } from "react"
import { useNavigate } from "react-router-dom"

// Components
import { LoginButton } from "components"

// Hooks
import { useFetch } from "hooks/fetch"

// Util
import { 
	ROUTE_CAMPAIGNS,
	// ROUTE_HIGHLIGHTS, 
	// ROUTE_TAGS, 
	// ROUTE_SIGNUP, 
	ROUTE_LOGIN, 
	// ROUTE_RESET
} from "util/Routes.const"
import { API_NUCLEUS_BACKEND_LOGIN } from "util/service.const"
import { STATUS_FAILURE } from "util/Status"
// import { APP_TYPE, APP_TYPE_ADMIN } from "util/const"

// Icons
import { ReactComponent as NucleusLogoIcon } from "assets/logo-icon.svg"
import { ReactComponent as NucleusLogoText } from "assets/logo-text.svg"
import { ReactComponent as IconGoogle } from "assets/icons/_tmp/google.svg"

// SVG assets
import { ReactComponent as PreviewIcon } from "assets/icons_mini/preview.svg"

// Styles
import "./AuthGateway.css"

// TODO: Streamline unilogin flow
export const AuthGateway = ({ routeKey, checkIfAuthReady }) => {
	// const isAdminApp = APP_TYPE === APP_TYPE_ADMIN

	const viewLabels = {
		"signup": {
			"header": "Create your 60-day free account",
			"button": ["Get started", "Wait...", "Sign up", "Wait...", "Validate", "Wait...", "Welcome!"]
		},
		"reset": {
			"header": "Create a new password",
			"button": ["Reset", "Wait...", "Validate", "Wait...", "Password changed!"]
		},
		"login": {
			"header": "Welcome back!",
			"button": ["Log in", "Wait...", "Welcome!"]
		}
	}
	const [buttonLabelIndex, setButtonLabelIndex] = useState(0)
	
	const fetch = useFetch()
	const navigate = useNavigate()

	const retrieveUserSession = async (data) => {

		// Google Auth0 API only
		const googleToken = data?.credential
		if (googleToken) {
			data = { 
				url: `${API_NUCLEUS_BACKEND_LOGIN}/user`,
				token: googleToken 
			}
		} 

		// Shared
    if (!data.url) {
      return
    }
    const response = await fetch(data)
    const { status, message, payload } = response
    if (STATUS_FAILURE === status) {
      return Promise.reject(message)
    }
    return Promise.resolve(payload)
  }

	const authCheck = (token) => { 			
		checkIfAuthReady({ token }).then(() => {
			// navigate(`/${ROUTE_HIGHLIGHTS}`)
			navigate(`/${ROUTE_CAMPAIGNS}`)

			// // TODO: Fix userCard.is_admin flag both here and in missing queries (if needed)
			// // -> Check the "nucleus-development-vlpnx.ondigitalocean.app/api/v1/property/all" problem when logged in as admin yet not 'is_admin' available
			// if(!isAdminApp) {// ALT: if(!isAdminApp || (isAdminApp && userCard.is_admin??? /* ~isAdminUsr */)) navigate(`/${ROUTE_TAGS}`)
			// 	navigate(`/${ROUTE_HIGHLIGHTS}`)
			// } else if (true) { //userCard.is_admin/* ~isAdminUsr */) {
			// 	navigate(`/${ROUTE_TAGS}`)
			// } else {
			// 	console.error("Admin mode requires 'userCard.is_admin' set to true")
			// }
		})	
	}

//   const authOkGoogle = async (response) => { 
// 		authCheck(response?.credential)
//   }
//   const authKoGoogle = (response) => {
//     console.log("onError", response)
//   }

	const uniloginButtonWrapper = useRef(undefined)
	// const onConsentChange = (e) => {
	// 	const target = tosInput.current// -> ALT: e.currentTarget.firstElementChild
	// 	e.preventDefault()
	// 	target.checked = !target.checked
	// }

	/* Universal unilogin page */
	// const otpDigits = 6
	// const otpPlaceholder = "  • ".repeat(otpDigits - 1) + " "
	// const emailToken = useRef()
	// const signupToken = useRef()
	// const resetToken = useRef()
	const formWrapper = useRef(undefined)
	// const tosInput = useRef(undefined)
	const uniForm = useRef({})
	const [emailHint, setEmailHint] = useState("")
	const [passwordHint, setPasswordHint] = useState("")

	// const resendOtp = (e) => {
	// 		e.preventDefault();
	// 		const otp = uniForm.current.otp
	// 		const submitButton = uniForm.current.submitButton

	// 		// Ready to post the email address (1/3)
	// 		setButtonLabelIndex(3)
	// 		submitButton.classList.add("unilogin__button--await")
	// 		retrieveUserSession({
	// 			url: `${API_NUCLEUS_BACKEND_USER}/resend/${signupToken.current}`// TODO?: ${isAdminApp ? "?check_admin=1" : ""}`,
	// 		}).then(() => {
	// 			// ALT: if (response.signup_token === signupToken.current) {
	// 			setTimeout(() => {// -> Gives the user some time to react/read
	// 				setButtonLabelIndex(4)
	// 				submitButton.classList.remove("unilogin__button--await")
	// 				setTimeout(() => {
	// 					otp.parentElement.classList.add("unilogin__otp--reveal")
	// 					otp.focus()
	// 				}, 100/* For a more natural transition [after the "--await" loading animation has finished] */)
	// 			}, 300)
	// 		})
	// 		.catch((err) => {
	// 			console.log("OTP resend error", err)
	// 		})
	// }

	// // TODO: External email+buttom form component
	const onFormSubmit = (e) => {
		e.preventDefault()
		const email = uniForm.current.email
		const password = uniForm.current.password
		const submitButton = uniForm.current.submitButton
	// 	const tos = tosInput.current
	// 	const otp = uniForm.current.otp

	// 	// 1/4 - Ask for email
	// 	// TODO: Prevent email syntax checking while signing in (we only need "unknown" error handling here)
	// 	if (routeKey !== ROUTE_RESET && !email.checkValidity()) {
	// 		if (!email.value) {
	// 			setEmailHint("Required value")
	// 		} else {
	// 			setEmailHint("Invalid format")
	// 		}
	// 		const emailWrapperClasses = email.parentElement.classList
	// 		// email.blur()
	// 		emailWrapperClasses.remove("unilogin--shake", "unilogin--freeze")
	// 		email.focus()
	// 		emailWrapperClasses.add("unilogin--shake")
	// 		// TODO: Set an auto-hide timer once the error flag have been read (e.g. 2s) to get the original box width back
	// 		return
	// 	} else

	// 	// Email-ok tick
	// 	if (routeKey !== ROUTE_RESET) {
	// 		setEmailHint("")
	// 		email.parentElement.classList.add("unilogin--freeze")
	// 	}

	// 	// 2/4 - Ask for password
	// 	// TODO: Prevent password syntax checking while signing in (we only need "unauthorized" error handling here)
	// 	if (!password.checkValidity()) {
	// 		if (routeKey === ROUTE_SIGNUP && !password.parentElement.classList.contains("unilogin__password--reveal")) {

	// 			// Ready to post the email address (1/3)
	// 			setButtonLabelIndex(1)
	// 			submitButton.classList.add("unilogin__button--await")
	// 			retrieveUserSession({
	// 				url: `${API_NUCLEUS_BACKEND_USER}/signup/identifier`,// TODO?: ${isAdminApp ? "?check_admin=1" : ""}`,
    //       method: "POST",
    //       body: {
    //         email: email.value
    //       }
	// 			}).then((response) => {
	// 				emailToken.current = response.token

	// 				setTimeout(() => {// -> Gives the user some time to react/read
	// 					setButtonLabelIndex(2)
	// 					submitButton.classList.remove("unilogin__button--await")
	// 					setTimeout(() => {
	// 						password.focus()
	// 						password.parentElement.classList.add("unilogin__password--reveal")
	// 					}, 100/* For a more natural transition [after the "--await" loading animation has finished] */)
	// 				}, 300)

	// 			}).catch((err) => {
	// 				if (err.includes("exists"/* "User might already exists" */)) {
	// 					setEmailHint("Already registered")
	// 					setButtonLabelIndex(2)
	// 					submitButton.classList.remove("unilogin__button--await")

	// 					// TODO: Make a shared function for "Shake"
	// 					const emailWrapperClasses = email.parentElement.classList
	// 					emailWrapperClasses.remove("unilogin--shake", "unilogin--freeze")
	// 					email.focus()
	// 					emailWrapperClasses.add("unilogin--shake")
	// 					return
	// 				}
	// 			})
	// 		} else {

	// 			// Password syntax checking
	// 			const pvalue = password.value
	// 			if (/[\s]/.test(pvalue)) {
	// 				setPasswordHint("Invalid char")
	// 			} else 
	// 			if (pvalue.length < 8) {
	// 				setPasswordHint("Min 8 chars")
	// 			} else 
	// 			if (!/[a-z]/.test(pvalue)) {
	// 				setPasswordHint("Min 1 lowercase")
	// 			} else 
	// 			if (!/[A-Z]/.test(pvalue)) {
	// 				setPasswordHint("Min 1 uppercase")
	// 			} else 
	// 			if (!/\d/.test(pvalue)) {
	// 				setPasswordHint("Min 1 number")
	// 			} else
	// 			if (!/[\W_]/.test(pvalue)) {
	// 				setPasswordHint("Min 1 special char")
	// 			}
	// 			const passwordWrapperClasses = password.parentElement.classList
	// 			passwordWrapperClasses.remove("unilogin--shake", "unilogin--freeze")
	// 			password.focus()
	// 			passwordWrapperClasses.add("unilogin--shake")
	// 			return		
	// 		}
	// 	} else {
						
	// 		// Password-ok tick
	// 		setPasswordHint("")
	// 		password.parentElement.classList.add("unilogin--freeze")
		
	// 		// 3/4 - Ask for consent
	// 		if (routeKey === ROUTE_SIGNUP && !tos.checked) {
	// 			const termsWrapperClasses = tos.parentElement.parentElement.classList
	// 			termsWrapperClasses.remove("unilogin--shake")
	// 			tos.parentElement.focus()
	// 			termsWrapperClasses.add("unilogin--shake")
	// 			return
	// 		} else 

	// 		// 4/4 - Ask for verification
	// 		if (routeKey === ROUTE_SIGNUP && !otp.checkValidity()) {
	// 			if (!otp.parentElement.classList.contains("unilogin__otp--reveal")) {

	// 				// Ready to post the password (2/3)
	// 				setButtonLabelIndex(3)
	// 				submitButton.classList.add("unilogin__button--await")
	// 				retrieveUserSession({
	// 					url: `${API_NUCLEUS_BACKEND_USER}/signup/password?state=${emailToken.current}`,// TODO?: ${isAdminApp ? "&check_admin=1" : ""}
	// 					method: "POST",
	// 					body: {
	// 						password: password.value
	// 					}
	// 				}).then((response) => {
	// 					signupToken.current = response.signup_token
	// 					//setTimeout(() => {// -> Gives the user some time to react/read
	// 						setButtonLabelIndex(4)
	// 						submitButton.classList.remove("unilogin__button--await")
	// 						setTimeout(() => {
	// 							otp.parentElement.classList.add("unilogin__otp--reveal")
	// 							otp.focus()
								
	// 							// Reveal the "OTP resend" link after the first TTL has expired
	// 							const resendClass = otp.parentElement?.lastElementChild?.lastElementChild?.classList
	// 							setTimeout(() => {
	// 								resendClass.add("unilogin-element-otp__info__suffix--show")
	// 							}, 5000)/* -> TODO: Match the OTP expiration/TTL time */ 
	// 						}, 100/* For a more natural transition (no "--await" loading animation here but same shake fx sync) */)
	// 					//}, 300)

	// 				}).catch((err) => {
	// 					console.log("Password error", err)
	// 				})
	// 			} else {
	// 				const otpWrapperClasses = otp.parentElement.classList
	// 				otpWrapperClasses.remove("unilogin--shake")
	// 				otp.focus()
	// 				otpWrapperClasses.add("unilogin--shake")
	// 			}

	// 		// Ready to go (Sign-up view)
	// 		} else 
	// 		if (routeKey === ROUTE_SIGNUP) {

	// 			// Ready to post the validation code (3/3)
	// 			setButtonLabelIndex(5)
	// 			submitButton.classList.add("unilogin__button--await")
	// 			retrieveUserSession({
	// 				url: `${API_NUCLEUS_BACKEND_USER}/verify`,// TODO?: ${isAdminApp ? "?check_admin=1" : ""}`,
	// 				method: "POST",
	// 				body: {
	// 					otp_code: otp.value.replace(/\D/g, ""),
	// 					signup_token: signupToken.current
	// 				}
	// 			}).then(() => {
	// 				setTimeout(() => {// -> Gives the user some time to react/read
	// 					setButtonLabelIndex(6)
	// 					submitButton.classList.remove("unilogin__button--await")
	// 					setTimeout(() => {
	// 							authCheck(emailToken.current)
	// 					}, 100/* For a more natural transition [after the "--await" loading animation has finished] */)
	// 				}, 300)

	// 			}).catch((err) => {
	// 				console.log("OTP error", err)
	// 			})

	// 		// Ready to go (Login view)
	// 		} else 
	// 		if (routeKey === ROUTE_LOGIN) {

				// Ready to post the login credentials
				// setButtonLabelIndex(1)
				submitButton.classList.add("unilogin__button--await")
				retrieveUserSession({
					url: `${API_NUCLEUS_BACKEND_LOGIN}`,// TODO?: ${isAdminApp ? "?check_admin=1" : ""}`,
					method: "POST",
					body: {
						email: email.value,
						password: password.value
					}
				}).then((response) => {
					setTimeout(() => {// -> Gives the user some time to react/read
						setButtonLabelIndex(2)
						submitButton.classList.remove("unilogin__button--await")
						setTimeout(() => {
								authCheck(response.token)
						}, 100/* For a more natural transition [after the "--await" loading animation has finished] */)
					}, 300)

				}).catch((err) => {
					console.log("Login error", err)
					setTimeout(() => {// -> Gives the user some time to react/read
						setButtonLabelIndex(0)
						submitButton.classList.remove("unilogin__button--await")
						if (err === "Could not find user") {
							setEmailHint("Unknown user")
							const emailWrapperClasses = email.parentElement.classList
							emailWrapperClasses.remove("unilogin--shake", "unilogin--freeze")
							email.focus()
							emailWrapperClasses.add("unilogin--shake")
							return
						} else {// -> if (err === "User doesn't exists -> "Could not find user. E-mail or password might not be correct")
							setPasswordHint("Wrong password")

							// Buffer the email address for this session/tab only (so we can pre-fill the email field while reseting the password)
							sessionStorage.setItem("nucleus-buffer", JSON.stringify(email.value))
							
							setTimeout(() => {
								submitButton.previousElementSibling.classList.add("unilogin__redirect-pre--reveal")
							}, 600 + 100/* Wait until '--shake' is done & 100ms for a more natural transition [after the "--await" loading animation has finished] */)
							const passwordWrapperClasses = password.parentElement.classList
							passwordWrapperClasses.remove("unilogin--shake", "unilogin--freeze")
							password.focus()
							passwordWrapperClasses.add("unilogin--shake")
							return
						}
					}, 300)
				})

	// 		// Ready to go (Password recovery view)
	// 		} else 
	// 		if (routeKey === ROUTE_RESET) {
	// 			if (!resetToken.current) {

	// 				// Ready to post the login credentials
	// 				setButtonLabelIndex(1)
	// 				submitButton.classList.add("unilogin__button--await")
	// 				retrieveUserSession({
	// 					url: `${API_NUCLEUS_BACKEND_USER}/reset`,// TODO?: ${isAdminApp ? "?check_admin=1" : ""}`,
	// 					method: "POST",
	// 					body: {
	// 						email: email.value
	// 					}
	// 				}).then((response) => {
	// 					resetToken.current = response.token
	// 					setTimeout(() => {// -> Gives the user some time to react/read
	// 						setButtonLabelIndex(2)
	// 						submitButton.classList.remove("unilogin__button--await")
	// 						setTimeout(() => {
	// 							otp.parentElement.classList.add("unilogin__otp--reveal")
	// 							otp.focus()
	// 						}, 100/* For a more natural transition [after the "--await" loading animation has finished] */)
	// 					}, 300)

	// 				}).catch((err) => {
	// 					setButtonLabelIndex(0)
	// 					console.log("Reset error", err)// -> Could not find user
	// 				})
	// 			} else {

	// 				// Ready to post the login credentials
	// 				setButtonLabelIndex(3)
	// 				submitButton.classList.add("unilogin__button--await")
	// 				retrieveUserSession({
	// 					url: `${API_NUCLEUS_BACKEND_USER}/reset/verify?reset_token=${resetToken.current}`,// TODO?: ${isAdminApp ? "?check_admin=1" : ""}`,
	// 					method: "POST",
	// 					body: {
	// 						otp_code: otp.value.replace(/\D/g, ""),
	// 						password: password.value
	// 					}
	// 				}).then(() => {
	// 					//if (response && !Object.keys(response)?.length) {
	// 					setTimeout(() => {// -> Gives the user some time to react/read
	// 						setButtonLabelIndex(4/* Password changed! */)
	// 						submitButton.classList.remove("unilogin__button--await")
	// 						submitButton.classList.add("unilogin__button--done")
	// 						// TODO: Resend support [ideally as part of a shared meta-component] for the reset OTP (as we already did for the signup view) 
	// 					}, 300)
	// 					//}
	// 				}).catch((err) => {
	// 					setButtonLabelIndex(0)
	// 					console.log("Reset error", err)
	// 				})
	// 			}
	// 		}
	// 	}
	}
	const onUserInputUp = (e) => {
		const input = uniForm.current[e.target.id]

		// Disable the Google button if the user already has the intention to use Nucleus' instead
		if (input.type === "email") {
			const toggle = input.value ? "add" : "remove"
			const object = uniloginButtonWrapper.current
			if (object) {
				// if (routeKey === ROUTE_SIGNUP ) {
				// 	uniloginButtonWrapper.current.parentElement.classList[toggle]("unilogin__footer--toggle")
				// } else {
					object.classList[toggle]("unilogin--toggle")
					object.previousElementSibling.classList[toggle]("unilogin--toggle")
				// }
			}
		}
	}
	const onUserInputDn = (e) => {
		const input = uniForm.current[e.target.id]
		input?.parentElement.classList.remove("unilogin--shake")// -> Remove the error info box at the right
	}
	useEffect(() => {
		uniForm.current = {
			email: formWrapper.current.elements["email"],
			password: formWrapper.current.elements["password"],
			// otp: formWrapper.current.elements["otp"],
			submitButton: formWrapper.current.elements["submit-button"]
		}
		// uniForm.current[routeKey !== ROUTE_RESET ? "email" : "password"].focus()

		// Get sure both the user knows what email account is trying to recover
		// if (routeKey === ROUTE_RESET) {

		// 	// TODO: Try to have 'emailBuffer' once (or 'userCard.email' value) vs having it at App.js too 
		// 	const emailBuffer = JSON.parse(sessionStorage.getItem("nucleus-buffer"))
		// 	if (emailBuffer) {
		// 		const email = formWrapper.current.elements["email"]
		// 		email.value = emailBuffer
		// 		email.disabled = true
		// 		sessionStorage.removeItem("nucleus-buffer")
		// 	}
		// }

	}, [])
	// }, [routeKey])

	return (
		<main className="unilogin-wrapper">
			<div className="unilogin">

				{/* Header */}
				<NucleusLogoIcon className="unilogin__logo-icon" />
				<NucleusLogoText className="unilogin__logo-text" />
				{/* <span className={`unilogin__header ${routeKey === ROUTE_RESET ? "unilogin__header-over-placeholderless-input" : ""} text-l`}>{viewLabels[routeKey]?.header}</span> */}

				{/* Traditional sign-up form */}
				<form ref={formWrapper} className="unilogin__form" noValidate onSubmit={onFormSubmit}>
	
					{/* 1/3 - Email */}
					<div className="unilogin--place unilogin-element unilogin-element-email">
						<input id="email" className="unilogin-input" type="email" pattern="^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$" placeholder="&nbsp;" spellCheck="false" autoComplete="off" name="ForceSFtoNotIgnoreAutoCompleteOffWithThisSearchHack" required onKeyUp={onUserInputUp} onKeyDown={onUserInputDn}/>
						{/* {routeKey !== ROUTE_RESET && <label htmlFor="email" className="unilogin-placeholder text-l">Your email</label>} */}
						<label htmlFor="email" className="unilogin-placeholder text-l">Your email</label>
						<span className="unilogin-info text-s">{emailHint}</span>
					</div>

					{/* 2/3 - Password */}
					{/* <div className={`unilogin--place unilogin-element unilogin-element-password ${routeKey !== ROUTE_SIGNUP ? "unilogin__password--reveal" : ""}`}> */}
					<div className={`unilogin--place unilogin-element unilogin-element-password unilogin__password--reveal`}>
						<input id="password" className="unilogin-input" type="password" placeholder="&nbsp;" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[^\s]{8,}" spellCheck="false" autoComplete="off" name="ForceSFtoNotIgnoreAutoCompleteOffWithThisSearchHack" required onKeyDown={onUserInputDn}/>
						{/* <label htmlFor="password" className="unilogin-placeholder text-l">{`Your ${routeKey === ROUTE_RESET ? "new" : ""} password`}</label> */}
						<label htmlFor="password" className="unilogin-placeholder text-l">{`Your password`}</label>
						<span className="unilogin-info text-s">{passwordHint}</span>
						<PreviewIcon className="unilogin-preview" onMouseDown={(e) => {
								e.preventDefault()
								const input = e.target.parentElement.firstElementChild
								input.type = input.type === "text" ? "password" : "text"
								input.parentElement.classList.toggle("unilogin-element-password--unlock")
								input.parentElement.classList.contains("unilogin--freeze") || input.focus()
							}
						}/>
					</div>

					{/* 3/3 - OTP */}
					{/* TODO: Build a reusable OTP component out of this block */}
					{/* <div className="unilogin--place unilogin-element unilogin-element-otp">
						<input id="otp" className="unilogin-input" type="tel" placeholder={otpPlaceholder} pattern={`\\d( • \\d){${otpDigits - 1},}`} spellCheck="false" autoComplete="off" name="ForceSFtoNotIgnoreAutoCompleteOffWithThisSearchHack" required
							onFocus={(e) => {
								if (!e.target.value) {
									e.preventDefault()
									e.target.value = otpPlaceholder
									e.target.setSelectionRange(0, 0)
								}
							}}
							// TODO: Prevent placeholder to block the "Your OTP" flag animation on focus/blur
							// onBlur={(e) => {
							// 	if (e.target.value === otpPlaceholder) {
							// 		//e.preventDefault()
							// 		e.target.value = ""
							// 	}
							// }}
							onMouseDown={(e) => {
								e.preventDefault()
								const idx = e.target.value.match(/\d+/g)?.length * 4
								e.target.setSelectionRange(idx, idx)
								e.target.focus() 
							}}
							onChange={(e) => {// -> React's non-standard onInput override yet usually recommended (check https://stackoverflow.com/questions/38256332)
								const numValue = e.target.value.replace(/\D/g, "").slice(0, otpDigits)
								if (!numValue) {
									e.target.value = otpPlaceholder
									e.target.setSelectionRange(0, 0)
								} else {
									let numChain = ""
									const numTimes = numValue.length - 1
									for (let i = 0; i < numTimes; i++) {
										numChain += (numValue[i] + " • ")
									}
									numChain += numValue[numTimes] + " •  ".repeat(otpDigits - numTimes - 1)
									e.target.value = numChain
									const idx = 4 + (numTimes * 4)
									e.target.setSelectionRange(idx, idx)
								}
							}}
							onKeyDown={(e) => {
								if (e.key === "Backspace") {
									if (e.target.value[e.target.selectionEnd]) {// ALT: e.target.value.match(/\d+/g)?.length < otpDigits) OR e.target.value[(otpDigits - 1) * 4] === " "
										const str = e.target.value
										const selectAt = e.target.selectionEnd - 4
										e.target.value = str.slice(0, selectAt) + " " + str.slice(selectAt + 1)// ALT: 'substring' instead of 'slice' OR e.target.value = str.replace(new RegExp(`^(.{${ selectAt }})(.)`), `$1 `)
									}
								} else 
								if (e.key.includes("Arrow")) {// ALT: e.key.slice(0, 5) === "Arrow"
									e.preventDefault()
								} else {
									onUserInputDn(e)
								}
							}}
							
						/>
						<label htmlFor="otp" className="unilogin-placeholder text-l">Your code</label>
						<div className="unilogin-element-otp__mask">
							{new Array(otpDigits - 1).fill().map((_, index) => <span key={index}></span>)}
						</div>
						<span className="unilogin-element-otp__info text-s">
							↓ Check your email inbox<span className="unilogin-element-otp__info__suffix text-s">&nbsp;←&nbsp;<a href="#" onClick={resendOtp}>Resend</a></span>
						</span>
					</div> */}
					

					{/* View redirection (pre-button) */}					
					{/* <div className="unilogin__redirect-pre text-s">
						Forgot your password?&nbsp;<a href={`/${ROUTE_RESET}`} target="_self" rel="noreferrer">Reset it ↗</a>
					</div> */}

					{/* Shared submit */}
					{/* TODO: Prevent placeholder animation/s on focus/blur while hovering this */}
					<button className="unilogin__button text-l" type="submit" id="submit-button">
						<span className="unilogin__button__label">Login</span>
					</button>
					
				</form>

				{/* <div className="unilogin__footer"> */}

					{/* View redirection (post-button) */}
					{/* <div className="unilogin__redirect-post text-s">
						{routeKey === ROUTE_SIGNUP && <>Already have an account? <a href={`/${ROUTE_LOGIN}`} target="_self" rel="noreferrer">Log in ↗</a></>}
						{routeKey === ROUTE_LOGIN && <>First time around here? <a href={`/${ROUTE_SIGNUP}`} target="_self" rel="noreferrer">Sign up ↗</a></>}
						{routeKey === ROUTE_RESET && <>Do you remember now? <a href={`/${ROUTE_LOGIN}`} target="_self" rel="noreferrer">Log in ↗</a></>}
					</div> */}

					{/* {routeKey !== ROUTE_RESET && <>

						{/* OR divider */}
						{/* <div className="unilogin__or">
							<div className="text-s">OR</div>
							<div></div>
						</div> */}

						{/* Google Auth0 widget (width set to the internal iframe max-width) */}
						{/* TODO: 
								- Prevent the button [in 'Submit with...' view] to function as such (i.e. wiggle instead) is the TOS are not ticked 
								- For the 'Login with...' view, once the Google user loads its credentials, check if already locally stored and wiggle the whole button & tick block [from the Login view] if not (~better yet; just a msg w/ sth like "You need to signup first")
								- Try and route the main button into the One Tap one for a truly one-click experience. 
									> UPDATE: We can't do that b/c the user might want to choose between several Google accounts.
						*/}
						{/* <div ref={uniloginButtonWrapper} className="unilogin__google__wrapper text-l">
							<div className="unilogin__google__wrapper__overlay">{`${routeKey === ROUTE_LOGIN ? "Log in" : "Sign up"} with Google`}</div>
							<IconGoogle className="unilogin__google__wrapper__logo"/>
							<LoginButton width="300px" provider="google" authOk={authOkGoogle} authKo={authKoGoogle}/>
						</div> */}
					{/* </>} */} 

					{/* TOS agreement */}
					{/* {routeKey === ROUTE_SIGNUP &&
					<div className="unilogin__tos-wrapper">
						<span className="unilogin__tos text-s">I agree to the&nbsp;<a href="/legal/tos.html" target="_self" rel="noreferrer">Terms of Service</a></span>
						<div className="unilogin__consent" onClick={onConsentChange}>
							<input ref={tosInput} className="unilogin__consent__toggle__checkbox" id="consent" type="checkbox"/>
							<label className="unilogin__consent__toggle" htmlFor="consent"/>
						</div>
					</div>
					} */}
				{/* </div> */}
			</div>
		</main>
	)
}