// Contexts
import {
  useGlobals
} from "contexts"

// Containers
import { 
    Grid,
    Layout
} from "containers"

// Components
import {
  TableSort,
  Chart
} from "components"

import {
  ROTANA_DEMO_MODE
} from "util/const"

// Categories-wide styling
import "./IAB.css"

// Remember first access (& avoid re-rendering)
// let firstAccess = true

// IAB assembly
export const IAB = ({ready}) => {

  // --- Shared ---
  const globals = useGlobals() 

  // const [group, setGroup] = useState()

  // const picks = {
  //   "Usr": globals.get("checkboxed"),
  //   "All": ["unknown", "other_category", "bike", "minecraft", "casual", "racing", "simulator", "shooter", "puzzle", "sport", "single_player", "automotive"],
  //   "Low": ["unknown", "other_category", "bike", "minecraft", "casual", "racing", "simulator", "shooter", "automotive"],
  //   "Mid": ["unknown", "other_category", "bike", "minecraft", "casual", "racing", "simulator", "shooter", "puzzle", "sport", "single_player"],
  //   "Top": ["unknown", "other_category", "bike", "minecraft", "casual", "racing", "simulator", "shooter", "puzzle", "sport", "single_player", "automotive"]
  // }[!group ? "All" : (firstAccess ? group : "Usr")]

  // // ALT: Support renderless 'globals.set("checkboxed", true/*no-refresh*/)' instead (see TODO at 'Globals.jsx')
  // firstAccess = !group

  // // Reviewed totals (from low+mid+top)
  // let obj_all = {
  //   "all_genres": (354190 / 354190) * 100,
  //   "unknown": (162177 / 354190 ) * 100,  
  //   "other_category": (30574 / 354190 ) * 100,
  //   "bike": (29168 / 354190 ) * 100,
  //   "minecraft": (26368 / 354190 ) * 100,
  //   "casual": (24684 / 354190 ) * 100,
  //   "racing": (20772 / 354190 ) * 100,
  //   "simulator": (1590 / 354190 ) * 100,
  //   "shooter": (1614 / 354190 ) * 100,
  //   "puzzle": (21844 / 354190 ) * 100,
  //   "sport": (17211 / 354190 ) * 100,
  //   "single_player": (15675 / 354190 ) * 100,
  //   "automotive": (3403 / 354190 ) * 100
  // }

  // let arr_sel = []
  // let txt_sel = []
  // for (let i = 0; i < picks?.length; i++) {
  //   let code = picks[i].replaceAll("checkboxgroup-", "")
  //   let text = code[0].toUpperCase() + code.slice(1)
  //   arr_sel.push(obj_all[code])
  //   txt_sel.push(text)
  // }
  // // --- Shared ---

  // Unpack engagement grouping settings
  // const group = globals?.context?.selected?.[0] || "_"
  // const group = globals.get("dropdown_selection") || "_"
  const group = globals?.context?.dropdown_selection || "_"

  // // Dummy data loader (snow owl demo only)
  // const arr_all_ = {
  //   "_": [
  //     [62000, 44, [25, 39, 37]],
  //     [17000, 70, [25, 39, 37]],
  //     [66000, 41, [25, 39, 37]],
  //     [27000, 56, [25, 39, 37]],
  //     [62000, 44, [25, 39, 37]],
  //     [17000, 70, [25, 39, 37]],
  //     [66000, 41, [25, 39, 37]],
  //     [27000, 56, [25, 39, 37]],
  //     [39000, 37, [25, 39, 37]]
  //   ],
  //   "high": [
  //     [54000, 44, [25, 39, 37]],
  //     [13000, 66, [25, 39, 37]],
  //     [17000, 34, [25, 39, 37]],
  //     [18000, 36, [25, 39, 37]],
  //     [54000, 44, [25, 39, 37]],
  //     [13000, 66, [25, 39, 37]],
  //     [17000, 34, [25, 39, 37]],
  //     [18000, 36, [25, 39, 37]],
  //     [39000, 37, [25, 39, 37]]
  //   ],
  //   "medium": [
  //     [42000, 84, [25, 39, 37]],
  //     [37000, 63, [25, 39, 37]],
  //     [43000, 21, [25, 39, 37]],
  //     [67000, 34, [25, 39, 37]],
  //     [42000, 84, [25, 39, 37]],
  //     [37000, 63, [25, 39, 37]],
  //     [43000, 21, [25, 39, 37]],
  //     [67000, 34, [25, 39, 37]],
  //     [39000, 37, [25, 39, 37]]
  //   ],
  //   "low": [
  //     [53000, 71, [25, 39, 37]],
  //     [61000, 15, [25, 39, 37]],
  //     [74000, 35, [25, 39, 37]],
  //     [44000, 22, [25, 39, 37]],
  //     [53000, 71, [25, 39, 37]],
  //     [61000, 15, [25, 39, 37]],
  //     [74000, 35, [25, 39, 37]],
  //     [44000, 22, [25, 39, 37]]
  //   ]
  // }[group] 


  // TODO: Merge all data (ugly last-minute workaround)
  // let arr_all_obj = {
  //   "_": {
  //     "automotive":  [62000, 44, [25, 39, 37]],
  //     "business": [17000, 70, [25, 39, 37]],
  //     "careers": [66000, 41, [25, 39, 37]],
  //     "education": [27000, 56, [25, 39, 37]],
  //     "family_&_parenting": [62000, 44, [25, 39, 37]],
  //     "food_&_drink": [17000, 70, [25, 39, 37]],
  //     "health_&_fitness": [66000, 41, [25, 39, 37]],
  //     "hobbies_&_interests": [27000, 56, [25, 39, 37]]
  //   },
  //   "high": {
  //     "automotive": [54000, 44, [25, 39, 37]],
  //     "business": [13000, 70, [25, 39, 37]],
  //     "careers": [17000, 41, [25, 39, 37]],
  //     "education": [18000, 56, [25, 39, 37]],
  //     "family_&_parenting": [54000, 44, [25, 39, 37]],
  //     "food_&_drink": [13000, 70, [25, 39, 37]],
  //     "health_&_fitness": [17000, 41, [25, 39, 37]],
  //     "hobbies_&_interests": [18000, 56, [25, 39, 37]]
  //   },
  //   "medium": {
  //     "automotive":  [42000, 44, [25, 39, 37]],
  //     "business": [37000, 70, [25, 39, 37]],
  //     "careers": [43000, 41, [25, 39, 37]],
  //     "education": [67000, 56, [25, 39, 37]],
  //     "family_&_parenting": [42000, 44, [25, 39, 37]],
  //     "food_&_drink": [37000, 70, [25, 39, 37]],
  //     "health_&_fitness": [43000, 41, [25, 39, 37]],
  //     "hobbies_&_interests": [67000, 56, [25, 39, 37]]
  //   },
  //   "low": {
  //     "automotive":  [53000, 44, [25, 39, 37]],
  //     "business": [61000, 70, [25, 39, 37]],
  //     "careers": [74000, 41, [25, 39, 37]],
  //     "education": [44000, 56, [25, 39, 37]],
  //     "family_&_parenting": [53000, 44, [25, 39, 37]],
  //     "food_&_drink": [61000, 70, [25, 39, 37]],
  //     "health_&_fitness": [74000, 41, [25, 39, 37]],
  //     "hobbies_&_interests": [44000, 56, [25, 39, 37]]
  //   }
  // }[group] 




  // TODO: Merge all data (ugly last-minute workaround)
  // const arr_all_obj = {
  //   "_": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   },
  //   "high": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   },
  //   "medium": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   },
  //   "low": {
  //       "action": [62000, 44, [25, 39, 37]],
  //       "bike": [17000, 70, [25, 39, 37]],
  //       "casual": [66000, 41, [25, 39, 37]],
  //       "minecraft": [27000, 56, [25, 39, 37]],
  //       "other_content": [62000, 44, [25, 39, 37]],
  //       "puzzle": [17000, 70, [25, 39, 37]],
  //       "racing": [66000, 41, [25, 39, 37]],
  //       "shooter": [27000, 56, [25, 39, 37]],
  //       "simulator": [62000, 44, [25, 39, 37]],
  //       "single_player": [17000, 70, [25, 39, 37]],
  //       "sport": [66000, 41, [25, 39, 37]],
  //       "unknown": [50000, 44, [0, 0, 0]]
  //   }
  // }[group] 
  // arr_sel.push(arr_all_obj[text.toLowerCase().replaceAll(" ", "_")])


  // TODO: Prevent making this mapping redundant (we should either use "Foo Bar" formats at all times for labeling or have some kind of external mapping file for all components)
  const names = {
    // "all_genres": "All Genres",
    "automotive": "Automotive",
    "business": "Business",
    "careers": "Careers",
    "education": "Education",
    "family_&_parenting": "Family & Parenting",
    "health_&_fitness": "Health & Fitness",
    "food_&_drink": "Food & Drink",
    "hobbies_&_interests": "Hobbies & Interests"
  }
  // const rows = {
  //   "_": [

  //     // Head
  //     ["IAB", 12, 12, 12, 12, 12, 12, 12, -2],

  //     // Body
  //     // ["All IABs",  39, 2972990, 100, 11, 1, 43, 2, 46, 3, 41, 63],
  //     ["Automotive", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Business", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Careers", 53, 230853, 3, 25, 1, 39, 2, 37],
  //     ["Education", 44, 100385, 3, 31, 1, 40, 2, 29],
  //     ["Family & Parenting", 39, 2972990, 100, 11, 1, 43, 2, 46],
  //     ["Health & Fitness", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Food & Drink", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Hobbies & Interests", 53, 230853, 3, 25, 1, 39, 2, 37]
  //   ],
  //   "high": [

  //     // Head
  //     ["IAB", 12, 12, 12, 12, 12, 12, 12, -2],

  //     // Body
  //     // ["All IABs",  39, 2972990, 100, 11, 1, 43, 2, 46, 3, 41, 63],
  //     ["Automotive", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Business", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Careers", 53, 230853, 3, 25, 1, 39, 2, 37],
  //     ["Education", 44, 100385, 3, 31, 1, 40, 2, 29],
  //     ["Family & Parenting", 39, 2972990, 100, 11, 1, 43, 2, 46],
  //     ["Health & Fitness", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Food & Drink", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Hobbies & Interests", 53, 230853, 3, 25, 1, 39, 2, 37]
  //   ],
  //   "medium": [

  //     // Head
  //     ["IAB", 12, 12, 12, 12, 12, 12, 12, -2],

  //     // Body
  //     // ["All IABs",  39, 2972990, 100, 11, 1, 43, 2, 46, 3, 41, 63],
  //     ["Automotive", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Business", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Careers", 53, 230853, 3, 25, 1, 39, 2, 37],
  //     ["Education", 44, 100385, 3, 31, 1, 40, 2, 29],
  //     ["Family & Parenting", 39, 2972990, 100, 11, 1, 43, 2, 46],
  //     ["Health & Fitness", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Food & Drink", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Hobbies & Interests", 53, 230853, 3, 25, 1, 39, 2, 37]
  //   ],
  //   "low": [

  //     // Head
  //     ["IAB", 12, 12, 12, 12, 12, 12, 12, -2],

  //     // Body
  //     // ["All IABs",  39, 2972990, 100, 11, 1, 43, 2, 46, 3, 41, 63],
  //     ["Automotive", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Business", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Careers", 53, 230853, 3, 25, 1, 39, 2, 37],
  //     ["Education", 44, 100385, 3, 31, 1, 40, 2, 29],
  //     ["Family & Parenting", 39, 2972990, 100, 11, 1, 43, 2, 46],
  //     ["Health & Fitness", 28, 473414, 28, 27, 1, 52, 2, 21],
  //     ["Food & Drink", 42, 208073, 5, 13, 1, 55, 2, 32],
  //     ["Hobbies & Interests", 53, 230853, 3, 25, 1, 39, 2, 37]
  //   ]
  // }[group]

  const picks_ = globals.get("checkboxed") || ["automotive", "business", "careers", "education", "family_&_parenting", "health_&_fitness", "food_&_drink", "hobbies_&_interests"]/* First access */
  let txt_sel_ = []
  let arr_sel = []
  let arr_dat = []
  let flags = [] //[5000, 10000, 15000, 20000, 25000, 30000]
  if (ready) {
    /*JSON*/const arr_all_obj = globals?.context?.data_json_test?.campaigns?.iab_chart?.[group]
    /*JSON*/flags = globals?.context?.data_json_test?.campaigns?.iab_chart_flags
    for (let i = 0; i < picks_?.length; i++) {
      let text = names[picks_[i].replaceAll("checkboxgroup-", "")]
      txt_sel_.push(text)
      arr_sel.push(arr_all_obj[text.toLowerCase().replaceAll(" ", "_")])
    }
    // arr_dat = rows
    /*JSON*/ arr_dat = [...globals?.context?.data_json_test?.campaigns?.iab_table?.[group]]
  }
  // --- Shared ---

  return (
    <Layout header={{
      "title": "IAB - (Example)",
      "search": ""
    }}>
    
      {/* TODO: Make header component out of this */}
      {/* <div className={`text-xl _tempDummyHeader _tempDummyHeader--${group ? "link" : ""}`}>
        <span onClick={() => setGroup()}>IAB </span>
        {group &&
        <>
          <span> → </span>
          <span>{group + " engagement groups"}</span>
        </>}
      </div> */}

    <div className="_tempDummyHeader">IAB - (Example)</div>

    <Grid
        content={[
          ready/* Here too to animate bars on 1st load */ ? <Chart 
          text={{
            // title: "Revenue Impact",
            // subtitle: "Statistics",
            //x_label: "",
            x_flags: txt_sel_,
            y_label: ROTANA_DEMO_MODE ? "Visits" : "Total reach", 
            y_flags: flags?.map((x) => x.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ","))// TODO: Shared at the top of chart.jsx/mapchart.jsx & Lib method instead (shared w/ <Insert/> & others)
          }}
          data={arr_sel}
          mode={7}
        /> : "",
        ]}
        template={`
          0
        `}
        cellSize={{
          w: 800, 
          h: 400
        }}
      />
      <Grid
        content={[
          <TableSort 
          data={arr_dat}
          stick={true}
          undim={{"high": 4, "medium": 5, "low": 6}[group]}
          mode={ROTANA_DEMO_MODE ? 2 : 0}
          />
        ]}
        template={`
            0
            `}
        cellSize={{
          w: 800, 
          h: 500
        }}
      />

    </Layout>
)}